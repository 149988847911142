/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, Outlet, useOutlet } from 'react-router-dom'
import { useEffect, useState } from 'react'
import i18n, { EDirection, ELangSupport } from '@/i18n'

import { useAuth } from '@/core/context'
import { Header } from '@/components/sections/Header'

export const MainLayout = () => {
  const { user } = useAuth()

  const [isCongrats, setCongrats] = useState<boolean>(false)

  useEffect(() => {
    document.body.dir = EDirection.rtl
    i18n.resolvedLanguage === ELangSupport.he ? EDirection.rtl : EDirection.ltr
  }, [])

  return (
    <>
      {<Header isCongrats={isCongrats} />}
      <div className={`content ${isCongrats ? 'content--congrats' : ''}`}>
        <Outlet context={[isCongrats, setCongrats]} />
      </div>
    </>
  )
}
