/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMask, IMaskInput } from 'react-imask'
import ReactGA from 'react-ga'

import { TUpdateLoanRequestPayload } from '@/core/api/types'

import { BackPage, RenderTitle } from '@/components/smart'
import { Button, EButtonType, RadioButtonGroup } from '@/components/ui'

import { EQuizStep } from '@/pages'
import './style.scss'

interface IRealEstateProps {
  setStep: Dispatch<SetStateAction<EQuizStep | undefined>>
  setUpdatePayload: Dispatch<SetStateAction<TUpdateLoanRequestPayload>>
  initialData: Pick<TUpdateLoanRequestPayload, 'propertyOwner' | 'rentPayment'>
}

const configBlocks = {
  inputMask: {
    mask: '₪{ }VALUE',
    blocks: {
      VALUE: {
        mask: IMask.MaskedNumber,
        min: 0,
        max: 500000,
        thousandsSeparator: ',',
      },
    },
  },
}

export const RealEstate = ({
  setStep,
  setUpdatePayload,
  initialData,
}: IRealEstateProps) => {
  const { t } = useTranslation()

  const [propertyOwner, setPropertyOwner] = useState(
    initialData.propertyOwner && initialData.propertyOwner !== 'N/A'
      ? initialData.propertyOwner
      : '',
  )
  console.log(initialData.rentPayment)
  const [isRentAvailable, setRentAvailable] = useState<boolean | undefined>(
    !initialData.rentPayment
      ? initialData.rentPayment === 'true'
        ? true
        : initialData.rentPayment === 'false'
        ? false
        : undefined
      : initialData.rentPayment === undefined
      ? undefined
      : true,
  )
  console.log(initialData.rentPayment)
  console.log('isRentAvailable:', isRentAvailable)

  const [input, setInput] = useState({
    rentPayment:
      initialData.rentPayment && initialData.rentPayment !== 'N/A'
        ? initialData.rentPayment
        : '',
  })

  const onInputChange = ({ name, value }: { name: string; value: string }) => {
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const radioEstateList = [
    {
      value: 'false',
      name: 'button-types',
      label: '',
      childrenComponent: (
        <div className="radio-children">
          <div className="radio-children__text">
            {t('realEstate.radioEstate.no')}
          </div>
        </div>
      ),
    },
    {
      value: 'true',
      name: 'button-types',
      label: '',
      childrenComponent: (
        <div className="radio-children">
          <div className="radio-children__text">
            {t('realEstate.radioEstate.yes')}
          </div>
        </div>
      ),
    },
  ]

  const radioRentList = [
    {
      value: 'true',
      name: 'button-types-rent',
      label: t('realEstate.radioRent.yes'),
    },
    {
      value: 'false',
      name: 'button-types-rent',
      label: t('realEstate.radioRent.no'),
    },
  ]

  const radioEstateGroupHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPropertyOwner(event.target.value)
  }
  const radioRentGroupHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // console.log(event.target.value)
    setRentAvailable(event.target.value === 'true' ? true : false)
  }

  const updateProfileHandler = () => {
    const payload = {
      propertyOwner,
      rentPayment:
        isRentAvailable && input.rentPayment ? input.rentPayment.trim() : '',
    } as TUpdateLoanRequestPayload

    ReactGA.event({
      category: 'Button Click',
      action: 'propertyOwner & rentPayment',
      label: 'Quiz Page',
    })
    ReactGA.send({
      fieldsObject: payload,
    })

    setUpdatePayload((prev) => ({
      ...prev,
      ...payload,
    }))

    setStep(EQuizStep.MERITAL_STATUS)
  }

  return (
    <>
      <BackPage onNavigate={() => setStep(EQuizStep.LOAN)} />
      <section className="real-estate">
        <RenderTitle titleKey={'realEstate.title'} />

        <form>
          <RadioButtonGroup
            className="radio-estate"
            label=""
            options={radioEstateList}
            onChange={radioEstateGroupHandler}
            selectedValue={propertyOwner}
          />
          <RadioButtonGroup
            className="radio-rent"
            label=""
            options={radioRentList}
            onChange={radioRentGroupHandler}
            // selectedValue={  (!!isRentAvailable).toString()}
            selectedValue={
              isRentAvailable === undefined
                ? ''
                : (!!isRentAvailable).toString()
            }
          />

          {isRentAvailable !== false && (
            <div className="input-wrapper">
              <IMaskInput
                name="rentPayment"
                blocks={configBlocks}
                value={input.rentPayment}
                placeholder={t('realEstate.input.placeholder')}
                mask="inputMask"
                disabled={!isRentAvailable}
                onAccept={(_value, _mask, e) => {
                  if (e) {
                    onInputChange({
                      name: (e!.target as any).name as string,
                      value: _mask._unmaskedValue,
                    })
                  }
                }}
              />
            </div>
          )}
        </form>

        <Button
          type={EButtonType.button}
          disabled={
            !propertyOwner ||
            isRentAvailable === undefined ||
            (isRentAvailable === true && !input.rentPayment)
          }
          onClick={updateProfileHandler}
        >
          {t('realEstate.next')}
        </Button>
      </section>
    </>
  )
}
