export const LodgingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="182"
      height="160"
      viewBox="0 0 182 160"
      fill="none"
    >
      <mask
        id="mask0_304_7069"
        maskUnits="userSpaceOnUse"
        x="0"
        y="25"
        width="175"
        height="88"
      >
        <path d="M0.123047 25H174.536V112.829H0.123047V25Z" fill="white" />
      </mask>
      <g mask="url(#mask0_304_7069)">
        <path
          d="M174.533 88.0012C174.533 75.5966 163.306 64.6492 146.189 58.1176C144.859 39.6891 120.52 25.002 90.689 25.002C63.0948 25.002 40.2018 37.5669 35.8673 54.0367C14.9689 59.0215 0.123047 71.6619 0.123047 86.4685C0.123047 94.7277 4.74282 102.313 12.4553 108.27C13.2853 108.913 14.1518 109.536 15.0526 110.14H155.999C156.638 111.776 157.002 112.711 157.002 112.711C158.062 112.081 159.081 111.43 160.054 110.76C160.352 110.556 160.646 110.349 160.938 110.14H163.964C164.464 109.531 164.925 108.909 165.343 108.27C166.087 107.137 166.694 105.961 167.158 104.749C171.868 99.7392 174.533 94.0423 174.533 88.0012Z"
          fill="#CEE6FA"
        />
      </g>
      <mask
        id="mask1_304_7069"
        maskUnits="userSpaceOnUse"
        x="88"
        y="101"
        width="76"
        height="52"
      >
        <path
          d="M88.2363 101.71H163.918V152.732H88.2363V101.71Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_304_7069)">
        <path
          d="M163.701 102.094L163.682 109.112L88.6162 152.74L88.6377 145.723L163.701 102.094Z"
          fill="#23357D"
        />
      </g>
      <mask
        id="mask2_304_7069"
        maskUnits="userSpaceOnUse"
        x="12"
        y="101"
        width="77"
        height="52"
      >
        <path
          d="M12.9277 101.71H88.6478V152.732H12.9277V101.71Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_304_7069)">
        <path
          d="M88.6369 145.723L88.6155 152.74L13.0605 109.112L13.082 102.097L88.6369 145.723Z"
          fill="#081F5F"
        />
      </g>
      <path
        d="M163.701 102.094L88.637 145.723L13.082 102.097L88.1458 58.4683L163.701 102.094Z"
        fill="#3657B6"
      />
      <path
        d="M86.3852 93.5583L129.192 69.7881L129.081 107.997L86.2715 131.767L86.3852 93.5583Z"
        fill="#D9E8FF"
      />
      <path
        d="M76.1507 72.4153L62.2035 49.4688L48.164 56.9753L34.1738 64.4455C34.1824 64.4691 34.1824 64.4927 34.1931 64.5163L37.3824 66.2749L38.7164 102.688L86.2717 131.767L86.8401 93.5582L90.098 95.3555L76.1507 72.4153Z"
        fill="#AEC0F2"
      />
      <path
        d="M34.1738 64.4453L34.8644 64.885L59.6791 51.8578L87.5843 97.7596L132.165 73.005L103.337 25.1172L88.3307 33.4152L74.2698 40.9239L34.1738 64.4453Z"
        fill="#304BA5"
      />
      <path
        d="M34.748 64.1365L60.5278 49.3656L74.4772 72.7755L88.4245 96.1789L131.572 72.0184L117.284 48.527L103.337 25.1172L89.2995 32.7761L75.2536 40.4264L34.748 64.1365Z"
        fill="#23357D"
      />
      <path
        d="M50.1281 72.7476L48.7383 73.361L48.8305 85.8069L48.8284 85.8047L48.8198 88.4556H48.8219L48.7812 103.634L50.0316 102.965L62.4668 110.145L62.5075 96.3569L62.514 93.7081L62.5526 79.9196L50.1281 72.7476Z"
        fill="#E6E7E8"
      />
      <path
        d="M61.5418 91.7029L61.5675 82.843L52.3494 77.5198L52.3237 86.3797L61.5418 91.7029ZM51.3779 75.6646L62.537 82.1074L62.5048 93.6975L51.3457 87.2547L51.3779 75.6646Z"
        fill="#AEC0F2"
      />
      <path
        d="M61.5272 97.32L61.5036 106.18L52.2812 100.855L52.307 91.9946L61.5272 97.32Z"
        fill="#081F5F"
      />
      <path
        d="M63.8114 80.6426L63.7728 94.429L62.5053 93.6977L62.5374 82.1075L51.3784 75.6647L51.3462 87.2549L50.0872 86.5278L50.0786 89.1766L51.3376 89.9036L51.3054 101.485L62.4624 107.928L62.4967 96.3464L63.7664 97.0778L63.7256 110.866L50.04 102.965L50.1258 72.7393L63.8114 80.6426Z"
        fill="#D1D3D4"
      />
      <path
        d="M63.7733 94.4291L63.7668 97.0778L62.4972 96.3465L51.3381 89.9037L50.0791 89.1766L50.0877 86.5278L51.3466 87.2549L62.5057 93.6977L63.7733 94.4291Z"
        fill="#D1D3D4"
      />
      <path
        d="M61.5681 82.8428L61.5423 91.7027L52.3242 86.3795L52.35 77.5195L61.5681 82.8428Z"
        fill="#081F5F"
      />
      <path
        d="M52.3073 91.9949L52.2815 100.855L61.5039 106.18L61.5275 97.3203L52.3073 91.9949ZM51.3057 101.485L51.3378 89.9038L62.4969 96.3466L62.4626 107.928L51.3057 101.485Z"
        fill="#AEC0F2"
      />
      <path
        d="M63.7257 110.866L62.3724 111.484L48.7383 103.653L49.9415 102.834L63.7257 110.866Z"
        fill="#E6E7E8"
      />
      <path
        d="M79.6592 110.671L68.6738 114.808L79.6592 121.444V110.671Z"
        fill="#E6E7E8"
      />
      <path
        d="M79.6586 93.6613L69.7542 87.8662L68.9027 87.3687L68.6689 114.808H68.6732L79.6586 110.671V93.6613Z"
        fill="#E6E7E8"
      />
      <path
        d="M58.8491 59.311C52.668 54.5861 46.5791 58.8241 46.5576 65.7795L47.6471 65.3141L58.3923 71.684L59.2287 72.1151L69.6072 77.9917L69.6007 77.5906C68.5541 70.5837 64.8994 63.9393 58.8491 59.311Z"
        fill="#D1D3D4"
      />
      <path
        d="M50.9775 62.1548C50.5979 63.0234 50.3877 64.0893 50.3856 65.3311L58.2568 69.8758L58.2546 70.5836L49.1416 65.3226C49.1437 63.8255 49.4118 62.5516 49.888 61.5264L50.9775 62.1548Z"
        fill="#AEC0F2"
      />
      <path
        d="M50.3848 65.3311C50.3869 64.0893 50.5971 63.0234 50.9767 62.1548L58.2645 66.3628L58.256 69.8759L50.3848 65.3311Z"
        fill="#081F5F"
      />
      <path
        d="M51.3184 61.4985C52.6438 59.3345 55.2518 58.8047 58.2823 60.3983L58.2673 65.5135L51.3184 61.4985Z"
        fill="#081F5F"
      />
      <path
        d="M50.2422 60.8768C51.7843 58.4125 54.7933 57.8313 58.285 59.6908L58.2828 60.3985C55.2523 58.805 52.6443 59.3347 51.3188 61.4988L50.2422 60.8768Z"
        fill="#AEC0F2"
      />
      <path
        d="M66.312 71.0106C66.6852 72.3146 66.889 73.6186 66.8847 74.8583L59.0049 70.3093L59.0156 66.7983L66.312 71.0106Z"
        fill="#081F5F"
      />
      <path
        d="M67.4027 71.6388C67.8703 73.2088 68.1298 74.7873 68.1255 76.2843L59.0039 71.0168L59.0061 70.3091L66.8858 74.8581C66.8901 73.6184 66.6864 72.3144 66.3132 71.0104L67.4027 71.6388Z"
        fill="#AEC0F2"
      />
      <path
        d="M59.0336 60.8315C62.0641 62.7382 64.6635 66.2728 65.9761 69.9617L59.0186 65.9446L59.0336 60.8315Z"
        fill="#081F5F"
      />
      <path
        d="M65.9757 69.9617C64.6631 66.2727 62.0637 62.7382 59.0332 60.8315L59.0353 60.1216C62.527 62.2942 65.5275 66.3499 67.0524 70.5836L65.9757 69.9617Z"
        fill="#AEC0F2"
      />
      <path
        d="M58.2501 71.4353L59.0029 70.8283L68.1245 76.2845C68.1288 74.7875 67.8693 73.209 67.4017 71.639L67.1443 70.6374L67.0521 70.5838C65.5272 66.3501 62.5267 62.2944 59.0351 60.1217L58.2844 59.6714C54.7928 57.8312 51.7837 58.4124 50.2416 60.8767L49.8448 61.6209L49.8877 61.5266C49.4116 62.5517 49.1435 63.8257 49.1414 65.3227L59.0029 70.8283L58.2501 71.4353L47.6465 65.3142C47.6658 58.3416 52.5965 55.532 58.6619 59.0344C64.6092 62.4702 69.4371 70.6975 69.6001 77.5907C69.6043 77.7236 69.6065 77.8588 69.6065 77.9917L58.2501 71.4353Z"
        fill="#E6E7E8"
      />
      <path
        d="M107.455 114.096V97.333L96.3322 104.651L96.3193 104.981L107.236 114.223L107.455 114.096Z"
        fill="#E6E7E8"
      />
      <path
        d="M96.1582 120.517L107.455 114.096L96.3191 104.981L96.1582 109.401V120.517Z"
        fill="#E6E7E8"
      />
      <path
        d="M78.3211 114.682L78.3705 97.3095L71.6252 93.4168L71.5759 110.787L78.3211 114.682ZM70.8102 90.8174L79.2069 95.6645L79.1469 117.286L70.748 112.439L70.8102 90.8174Z"
        fill="#AEC0F2"
      />
      <path
        d="M70.7477 112.438L70.8099 90.817L79.2066 95.6641L79.1465 117.285L70.7477 112.438ZM79.702 93.6888L69.9413 88.0503L69.8662 114.19L79.6591 119.872L79.702 93.6888Z"
        fill="#D1D3D4"
      />
      <path
        d="M78.3697 97.3092L78.3204 114.682L71.5752 110.787L71.6245 93.4165L78.3697 97.3092Z"
        fill="#081F5F"
      />
      <path
        d="M105.91 112.788L96.7437 118.081L96.7073 104.97L105.874 99.6772L105.91 112.788ZM96.1582 119.786L106.871 113.601L106.826 97.7598L96.1582 103.919V119.786Z"
        fill="#D1D3D4"
      />
      <path
        d="M104.976 101.492L105.006 112.016L97.6455 116.267L97.6133 105.743L104.976 101.492Z"
        fill="#081F5F"
      />
      <path
        d="M105.006 112.016L104.976 101.492L97.6131 105.743L97.6453 116.267L105.006 112.016ZM96.708 104.97L105.875 99.6772L105.911 112.788L96.7445 118.081L96.708 104.97Z"
        fill="#AEC0F2"
      />
      <path
        d="M125.067 104.134V87.3687L113.945 94.6887L113.932 95.0189L124.848 104.258L125.067 104.134Z"
        fill="#E6E7E8"
      />
      <path
        d="M113.771 110.553L125.068 104.134L113.932 95.019L113.771 99.4372V110.553Z"
        fill="#E6E7E8"
      />
      <path
        d="M123.526 102.823L114.359 108.117L114.323 95.0058L123.489 89.7126L123.526 102.823ZM113.771 109.824L124.484 103.638L124.442 87.7974L113.771 93.9549V109.824Z"
        fill="#D1D3D4"
      />
      <path
        d="M122.588 91.5293L122.62 102.051L115.258 106.302L115.228 95.778L122.588 91.5293Z"
        fill="#081F5F"
      />
      <path
        d="M122.62 102.051L122.588 91.529L115.227 95.7777L115.257 106.302L122.62 102.051ZM114.322 95.0056L123.489 89.7124L123.525 102.823L114.359 108.119L114.322 95.0056Z"
        fill="#AEC0F2"
      />
      <path
        d="M41.1756 70.6031L42.4903 69.7495L42.3809 112.548L41.0684 113.401L41.1756 70.6031Z"
        fill="#E6E7E8"
      />
      <path
        d="M40.0244 69.859L41.337 69.0054L42.4909 69.7496L41.1761 70.6032L40.0244 69.859Z"
        fill="#86633F"
      />
      <path
        d="M41.1761 70.6031L41.0689 113.401L39.915 112.657L40.0244 69.8589L41.1761 70.6031Z"
        fill="#D1D3D4"
      />
      <path
        d="M53.3539 121.704L39.5482 113.678L39.5439 112.057L53.3496 120.08L53.3539 121.704Z"
        fill="#F1F2F2"
      />
      <path
        d="M53.3496 120.08L39.5439 112.056L48.6741 106.785L62.4798 114.808L53.3496 120.08Z"
        fill="#AEC0F0"
      />
      <path
        d="M62.4798 114.808L62.4841 116.43L53.3539 121.703L53.3496 120.08L62.4798 114.808Z"
        fill="#D9E8FF"
      />
      <path
        d="M56.11 118.486L42.3021 110.461L42.2979 108.829L56.1057 116.854L56.11 118.486Z"
        fill="#F1F2F2"
      />
      <path
        d="M56.1057 116.855L42.2979 108.829L48.6677 105.153L62.4756 113.176L56.1057 116.855Z"
        fill="#AEC0F0"
      />
      <path
        d="M62.4753 113.176L62.4796 114.808L56.1098 118.487L56.1055 116.855L62.4753 113.176Z"
        fill="#D9E8FF"
      />
      <path
        d="M58.8823 115.25L45.0766 107.224L45.0723 105.603L58.878 113.627L58.8823 115.25Z"
        fill="#F1F2F2"
      />
      <path
        d="M58.878 113.627L45.0723 105.603L48.6626 103.529L62.4683 111.555L58.878 113.627Z"
        fill="#AEC0F0"
      />
      <path
        d="M62.4692 111.555L62.4756 113.176L58.8832 115.25L58.8789 113.627L62.4692 111.555Z"
        fill="#D9E8FF"
      />
      <path
        d="M62.526 82.412L63.8171 81.5605L63.712 124.228L62.4209 125.079L62.526 82.412Z"
        fill="#E6E7E8"
      />
      <path
        d="M61.3945 81.6677L62.6857 80.8184L63.8181 81.5604L62.527 82.4119L61.3945 81.6677Z"
        fill="#86633F"
      />
      <path
        d="M62.527 82.4122L62.4219 125.08L61.2852 124.338L61.3945 81.668L62.527 82.4122Z"
        fill="#D1D3D4"
      />
      <path
        d="M69.9075 78.0239L69.9032 79.2121L63.0615 83.1584L63.0658 81.9702L69.9075 78.0239Z"
        fill="#E6E7E8"
      />
      <path
        d="M63.0651 81.97L63.0608 83.1582L39.6338 69.7343L39.6359 68.5439L63.0651 81.97Z"
        fill="#D1D3D4"
      />
      <path
        d="M69.9076 78.0237L63.0659 81.9701L39.6367 68.544L46.4784 64.5977L69.9076 78.0237Z"
        fill="#F1F2F2"
      />
      <path
        d="M87.3959 127.065L88.3825 126.493L88.3674 131.245L87.3809 131.82L87.3959 127.065Z"
        fill="#E6E7E8"
      />
      <path
        d="M87.3958 127.065L87.3807 131.82L86.748 131.454L86.7609 126.699L87.3958 127.065Z"
        fill="#D1D3D4"
      />
      <path
        d="M98.3244 121.408L99.311 120.833L99.2981 125.577L98.3115 126.15L98.3244 121.408Z"
        fill="#E6E7E8"
      />
      <path
        d="M97.6924 121.041L98.679 120.468L99.3117 120.833L98.3251 121.408L97.6924 121.041Z"
        fill="#AC7B09"
      />
      <path
        d="M98.3254 121.408L98.3126 126.15L97.6777 125.785L97.6927 121.041L98.3254 121.408Z"
        fill="#D1D3D4"
      />
      <path
        d="M100.492 123.173L101.478 122.6L101.465 127.355L100.477 127.928L100.492 123.173Z"
        fill="#E6E7E8"
      />
      <path
        d="M99.8477 122.802L100.834 122.229L101.478 122.6L100.491 123.173L99.8477 122.802Z"
        fill="#AC7B09"
      />
      <path
        d="M100.49 123.173L100.475 127.928L99.832 127.555L99.847 122.802L100.49 123.173Z"
        fill="#D1D3D4"
      />
      <path
        d="M89.8097 129.092L90.7963 128.52L90.7813 133.262L89.7969 133.834L89.8097 129.092Z"
        fill="#E6E7E8"
      />
      <path
        d="M89.291 129.189L90.2776 128.616L90.9124 128.983L89.9259 129.556L89.291 129.189Z"
        fill="#AC7B09"
      />
      <path
        d="M89.8098 129.092L89.797 133.834L89.1621 133.468L89.175 128.726L89.8098 129.092Z"
        fill="#D1D3D4"
      />
      <path
        d="M101.697 122.433L101.793 123.439L89.8387 130.367L89.7422 129.361L101.697 122.433Z"
        fill="#D1D3D4"
      />
      <path
        d="M89.7416 129.36L89.8381 130.366L86.7604 127.728L86.666 126.72L89.7416 129.36Z"
        fill="#D1D3D4"
      />
      <path
        d="M98.6208 119.792L101.696 122.433L89.7416 129.36L86.666 126.72L98.6208 119.792Z"
        fill="#F1F2F2"
      />
      <path
        d="M106.743 94.663L107.567 94.0796L115.586 126.585L114.762 127.169L106.743 94.663Z"
        fill="#E6E7E8"
      />
      <path
        d="M106.147 94.3539L106.973 93.7705L107.567 94.0794L106.744 94.6627L106.147 94.3539Z"
        fill="#D1D3D4"
      />
      <path
        d="M106.744 94.6628L114.763 127.169L114.165 126.86L106.147 94.354L106.744 94.6628Z"
        fill="#D1D3D4"
      />
      <path
        d="M114.279 89.953L115.105 89.3696L123.122 121.875L122.298 122.459L114.279 89.953Z"
        fill="#E6E7E8"
      />
      <path
        d="M113.694 89.6481L114.52 89.0669L115.106 89.3693L114.28 89.9527L113.694 89.6481Z"
        fill="#D1D3D4"
      />
      <path
        d="M114.28 89.953L122.299 122.459L121.714 122.156L113.694 89.6484L114.28 89.953Z"
        fill="#D1D3D4"
      />
      <path
        d="M113.726 122.872L112.611 122.291L112.323 121.144L113.439 121.725L113.726 122.872Z"
        fill="#D1D3D4"
      />
      <path
        d="M113.439 121.725L112.323 121.144L121.104 114.989L122.219 115.568L113.439 121.725Z"
        fill="#D1D3D4"
      />
      <path
        d="M122.219 115.568L122.509 116.715L113.726 122.873L113.438 121.725L122.219 115.568Z"
        fill="#E6E7E8"
      />
      <path
        d="M112.195 116.713L111.078 116.132L110.788 114.984L111.905 115.566L112.195 116.713Z"
        fill="#D1D3D4"
      />
      <path
        d="M111.905 115.565L110.788 114.984L119.562 108.833L120.68 109.414L111.905 115.565Z"
        fill="#D1D3D4"
      />
      <path
        d="M120.679 109.414L120.969 110.561L112.195 116.713L111.905 115.565L120.679 109.414Z"
        fill="#E6E7E8"
      />
      <path
        d="M110.614 110.714L109.497 110.133L109.207 108.975L110.322 109.558L110.614 110.714Z"
        fill="#D1D3D4"
      />
      <path
        d="M110.322 109.558L109.207 108.974L117.979 102.823L119.096 103.404L110.322 109.558Z"
        fill="#D1D3D4"
      />
      <path
        d="M119.096 103.405L119.386 104.563L110.614 110.714L110.322 109.558L119.096 103.405Z"
        fill="#E6E7E8"
      />
      <path
        d="M109.812 105.232L108.699 104.651L108.409 103.503L109.522 104.085L109.812 105.232Z"
        fill="#D1D3D4"
      />
      <path
        d="M109.522 104.085L108.409 103.504L117.188 97.3481L118.305 97.9272L109.522 104.085Z"
        fill="#D1D3D4"
      />
      <path
        d="M118.305 97.9272L118.595 99.0747L109.812 105.232L109.522 104.085L118.305 97.9272Z"
        fill="#E6E7E8"
      />
      <path
        d="M108.132 100.456L107.017 99.8769L106.728 98.7295L107.843 99.3107L108.132 100.456Z"
        fill="#D1D3D4"
      />
      <path
        d="M107.843 99.3101L106.728 98.7288L115.508 92.5713L116.626 93.1525L107.843 99.3101Z"
        fill="#D1D3D4"
      />
      <path
        d="M116.626 93.1528L116.916 94.3003L108.133 100.456L107.844 99.3104L116.626 93.1528Z"
        fill="#E6E7E8"
      />
      <path
        d="M110.522 57.5525L86.9618 70.9486C85.424 71.8237 83.4659 71.2853 82.5908 69.7454L82.5586 69.6896C81.6836 68.1497 82.2241 66.1916 83.7618 65.3165L107.322 51.9205C108.86 51.0454 110.818 51.5859 111.693 53.1237L111.725 53.1816C112.6 54.7193 112.062 56.6775 110.522 57.5525Z"
        fill="#081F5F"
      />
      <path
        d="M84.165 67.1953L107.723 53.8014C109.263 52.9263 111.219 53.4647 112.094 55.0046L112.126 55.0604C112.126 55.0625 112.128 55.0625 112.128 55.0646C112.188 54.4319 112.062 53.7757 111.725 53.1816L111.693 53.1237C110.818 51.5859 108.86 51.0454 107.322 51.9205L83.7618 65.3165C82.2241 66.1894 81.6836 68.1454 82.5586 69.6853C82.653 68.6795 83.2192 67.7336 84.165 67.1953Z"
        fill="#3657B6"
      />
      <path
        d="M106.432 36.4845L103.103 34.6379C101.246 33.4368 98.6143 33.5376 95.6996 35.2212C90.0632 38.4748 85.4971 46.4082 85.4971 52.9432C85.4971 56.2997 86.7024 58.6332 88.637 59.6691L88.6348 59.6713L91.7533 61.2841L91.7597 60.2439C92.9736 60.1324 94.3034 59.6884 95.6996 58.882C101.334 55.6306 105.9 47.695 105.9 41.1622C105.9 39.4206 105.578 37.9579 104.995 36.8062L106.432 36.4845Z"
        fill="#F4C04B"
      />
      <path
        d="M109.979 43.427C109.979 49.9599 105.413 57.8955 99.7768 61.149C94.1425 64.4026 89.5742 61.7431 89.5742 55.2081C89.5742 48.6752 94.1425 40.7397 99.7768 37.4861C105.413 34.2325 109.979 36.892 109.979 43.427Z"
        fill="#FCE4B1"
      />
      <path
        d="M107.313 44.9647C107.313 49.7926 103.94 55.6541 99.7769 58.0562C95.6139 60.4605 92.2402 58.4959 92.2402 53.6702C92.2402 48.8424 95.6139 42.983 99.7769 40.5766C103.94 38.1745 107.313 40.1391 107.313 44.9647Z"
        fill="#FCEED0"
      />
      <path
        d="M28.6741 90.1355C28.6741 99.2077 24.8371 106.564 24.8371 106.564C24.8371 106.564 20.998 99.2077 20.998 90.1355C20.998 81.0611 24.8371 73.7046 24.8371 73.7046C24.8371 73.7046 28.6741 81.0611 28.6741 90.1355Z"
        fill="#5BA6EB"
      />
      <path
        d="M17.3232 98.4397C19.8605 105.024 24.6775 106.564 24.6775 106.564C24.6775 106.564 25.3789 97.4295 22.8416 90.843C20.3044 84.2608 15.4873 82.7188 15.4873 82.7188C15.4873 82.7188 14.786 91.8532 17.3232 98.4397Z"
        fill="#ADD6F2"
      />
      <path
        d="M32.2088 98.3087C29.6716 104.895 24.8545 106.433 24.8545 106.433C24.8545 106.433 24.1532 97.2985 26.6904 90.7141C29.2276 84.1319 34.0447 82.5898 34.0447 82.5898C34.0447 82.5898 34.7482 91.7243 32.2088 98.3087Z"
        fill="#ADD6F2"
      />
      <path
        d="M17.6156 102.935C20.8005 107.109 24.8369 106.536 24.8369 106.536C24.8369 106.536 23.7024 99.199 20.5153 95.0253C17.3282 90.8538 13.2939 91.4243 13.2939 91.4243C13.2939 91.4243 14.4264 98.7636 17.6156 102.935Z"
        fill="#5BA6EB"
      />
      <path
        d="M32.0583 102.935C28.869 107.109 24.8369 106.536 24.8369 106.536C24.8369 106.536 25.9672 99.199 29.1564 95.0253C32.3435 90.8538 36.3778 91.4243 36.3778 91.4243C36.3778 91.4243 35.2432 98.7636 32.0583 102.935Z"
        fill="#5BA6EB"
      />
      <path
        d="M153.809 90.2983C153.809 99.3727 149.97 106.729 149.97 106.729C149.97 106.729 146.133 99.3727 146.133 90.2983C146.133 81.2261 149.97 73.8696 149.97 73.8696C149.97 73.8696 153.809 81.2261 153.809 90.2983Z"
        fill="#5BA6EB"
      />
      <path
        d="M142.458 98.6026C144.995 105.187 149.812 106.729 149.812 106.729C149.812 106.729 150.516 97.5924 147.976 91.0081C145.439 84.4237 140.622 82.8838 140.622 82.8838C140.622 82.8838 139.921 92.0182 142.458 98.6026Z"
        fill="#ADD6F2"
      />
      <path
        d="M157.346 98.4742C154.806 105.059 149.989 106.598 149.989 106.598C149.989 106.598 149.288 97.464 151.827 90.8797C154.364 84.2953 159.182 82.7554 159.182 82.7554C159.182 82.7554 159.883 91.8898 157.346 98.4742Z"
        fill="#ADD6F2"
      />
      <path
        d="M142.748 103.1C145.937 107.274 149.97 106.701 149.97 106.701C149.97 106.701 148.837 99.3621 145.65 95.1906C142.465 91.0169 138.429 91.5895 138.429 91.5895C138.429 91.5895 139.563 98.9246 142.748 103.1Z"
        fill="#5BA6EB"
      />
      <path
        d="M157.191 103.1C154.006 107.274 149.97 106.701 149.97 106.701C149.97 106.701 151.104 99.3621 154.291 95.1906C157.478 91.0169 161.513 91.5895 161.513 91.5895C161.513 91.5895 160.38 98.9246 157.191 103.1Z"
        fill="#5BA6EB"
      />
      <mask
        id="mask3_304_7069"
        maskUnits="userSpaceOnUse"
        x="6"
        y="0"
        width="176"
        height="120"
      >
        <path d="M6.71289 0H181.123V119.415H6.71289V0Z" fill="white" />
      </mask>
      <g mask="url(#mask3_304_7069)">
        <path
          d="M181.121 85.6584C181.121 68.7927 169.893 53.9083 152.776 45.0298C151.446 19.9737 127.108 0.00244141 97.2767 0.00244141C69.6825 0.00244141 46.7895 17.0862 42.455 39.4791C21.5566 46.2566 6.71289 63.4429 6.71289 83.5745C6.71289 94.8041 11.3305 105.119 19.043 113.217C19.873 114.091 20.7395 114.938 21.6403 115.759H162.586C163.228 117.984 163.59 119.255 163.59 119.255C164.649 118.398 165.668 117.513 166.642 116.603C166.94 116.325 167.234 116.043 167.526 115.759H170.552C171.054 114.931 171.513 114.085 171.931 113.217C172.675 111.676 173.282 110.078 173.745 108.43C178.455 101.618 181.121 93.8722 181.121 85.6584Z"
          fill="#CEE6FA"
        />
      </g>
      <mask
        id="mask4_304_7069"
        maskUnits="userSpaceOnUse"
        x="94"
        y="108"
        width="77"
        height="52"
      >
        <path
          d="M94.8223 108.299H170.506V159.318H94.8223V108.299Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_304_7069)">
        <path
          d="M170.287 108.681L170.268 115.698L95.2041 159.327L95.2234 152.311L170.287 108.681Z"
          fill="#23357D"
        />
      </g>
      <mask
        id="mask5_304_7069"
        maskUnits="userSpaceOnUse"
        x="19"
        y="108"
        width="77"
        height="52"
      >
        <path
          d="M19.5166 108.299H95.2345V159.318H19.5166V108.299Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_304_7069)">
        <path
          d="M95.2239 152.311L95.2046 159.327L19.6475 115.699L19.6689 108.683L95.2239 152.311Z"
          fill="#081F5F"
        />
      </g>
      <path
        d="M170.288 108.681L95.2239 152.311L19.6689 108.683L94.7327 65.0547L170.288 108.681Z"
        fill="#2563F6"
      />
      <path
        d="M92.9721 100.147L135.781 76.3745L135.667 114.583L92.8584 138.353L92.9721 100.147Z"
        fill="#D9E8FF"
      />
      <path
        d="M82.7366 79.0018L68.7894 56.0552L54.7499 63.5639L40.7598 71.034C40.7683 71.0555 40.7683 71.0791 40.7791 71.1027L43.9704 72.8614L45.3023 109.275L92.8577 138.353L93.4282 100.147L96.6839 101.942L82.7366 79.0018Z"
        fill="#AEC0F2"
      />
      <path
        d="M40.7598 71.0339L41.4504 71.4714L66.265 58.4442L94.1703 104.348L138.751 79.5914L109.923 31.7036L94.9166 40.0038L80.8557 47.5104L40.7598 71.0339Z"
        fill="#304BA5"
      />
      <path
        d="M41.335 70.7229L67.1147 55.9521L81.0641 79.3619L95.0114 102.765L138.159 78.6048L123.871 55.1135L109.924 31.7036L95.8865 39.3625L81.8405 47.0128L41.335 70.7229Z"
        fill="#2563F6"
      />
      <path
        d="M56.7169 79.3345L55.3271 79.9479L55.4172 92.3938H55.4151L55.4065 95.0426L55.4108 95.0447L55.3679 110.221L56.6204 109.554L69.0535 116.732L69.0942 102.946L69.1007 100.295L69.1393 86.5065L56.7169 79.3345Z"
        fill="#E6E7E8"
      />
      <path
        d="M68.1318 98.2894L68.1576 89.4294L58.9373 84.1062L58.9116 92.9661L68.1318 98.2894ZM57.9658 82.251L69.1249 88.6938L69.0927 100.284L57.9336 93.8412L57.9658 82.251Z"
        fill="#AEC0F2"
      />
      <path
        d="M68.114 103.906L68.0904 112.766L58.8701 107.443L58.8959 98.583L68.114 103.906Z"
        fill="#081F5F"
      />
      <path
        d="M70.4005 87.2294L70.3597 101.016L69.0922 100.284L69.1244 88.6942L57.9653 82.2514L57.9331 93.8416L56.6741 93.1145L56.6656 95.7633L57.9245 96.4903L57.8924 108.072L69.0493 114.515L69.0836 102.933L70.3533 103.667L70.3125 117.453L56.627 109.552L56.7127 79.3281L70.4005 87.2294Z"
        fill="#D1D3D4"
      />
      <path
        d="M70.3592 101.015L70.3528 103.666L69.0831 102.933L57.924 96.4901L56.665 95.763L56.6736 93.1143L57.9326 93.8413L69.0917 100.284L70.3592 101.015Z"
        fill="#D1D3D4"
      />
      <path
        d="M68.1571 89.4297L68.1314 98.2896L58.9111 92.9664L58.9369 84.1064L68.1571 89.4297Z"
        fill="#081F5F"
      />
      <path
        d="M58.8953 98.5835L58.8696 107.443L68.0898 112.767L68.1134 103.907L58.8953 98.5835ZM57.8916 108.072L57.9238 96.4902L69.0829 102.933L69.0485 114.515L57.8916 108.072Z"
        fill="#AEC0F2"
      />
      <path
        d="M70.3125 117.453L68.9591 118.071L55.3271 110.24L56.5303 109.421L70.3125 117.453Z"
        fill="#E6E7E8"
      />
      <path
        d="M86.2451 117.257L75.2598 121.397L86.2451 128.03V117.257Z"
        fill="#E6E7E8"
      />
      <path
        d="M86.2465 100.248L76.3421 94.4525L75.4906 93.957L75.2568 121.395L75.2611 121.397L86.2465 117.258V100.248Z"
        fill="#E6E7E8"
      />
      <path
        d="M65.4351 65.8993C59.2561 61.1723 53.1671 65.4103 53.1436 72.3657L54.2331 71.9003L64.9782 78.2702L65.8147 78.7034L76.1931 84.5778L76.1888 84.1768C75.14 77.172 71.4875 70.5255 65.4351 65.8993Z"
        fill="#D1D3D4"
      />
      <path
        d="M57.5634 68.7433C57.186 69.612 56.9736 70.6758 56.9715 71.9176L64.8427 76.4623L64.8405 77.1722L55.7275 71.909C55.7318 70.412 55.9978 69.138 56.4739 68.1128L57.5634 68.7433Z"
        fill="#AEC0F2"
      />
      <path
        d="M56.9727 71.9179C56.9748 70.6761 57.1871 69.6123 57.5646 68.7437L64.8524 72.9516L64.8439 76.4626L56.9727 71.9179Z"
        fill="#081F5F"
      />
      <path
        d="M57.9053 68.0875C59.2307 65.9213 61.8387 65.3915 64.8714 66.9872L64.8542 72.1003L57.9053 68.0875Z"
        fill="#081F5F"
      />
      <path
        d="M56.8281 67.4632C58.3702 64.9989 61.3793 64.4177 64.8709 66.2772V66.9871C61.8382 65.3914 59.2302 65.9212 57.9048 68.0873L56.8281 67.4632Z"
        fill="#AEC0F2"
      />
      <path
        d="M72.8989 77.597C73.2721 78.901 73.4759 80.205 73.4716 81.4447L65.5918 76.8957L65.6025 73.3848L72.8989 77.597Z"
        fill="#081F5F"
      />
      <path
        d="M73.9887 78.2274C74.4583 79.7973 74.7157 81.3737 74.7114 82.8708L65.5898 77.6033L65.592 76.8955L73.4718 81.4445C73.4761 80.2048 73.2723 78.9008 72.8991 77.5968L73.9887 78.2274Z"
        fill="#AEC0F2"
      />
      <path
        d="M65.6195 67.418C68.65 69.3246 71.2495 72.8613 72.562 76.5481L65.6045 72.5332L65.6195 67.418Z"
        fill="#081F5F"
      />
      <path
        d="M72.5626 76.5484C71.2501 72.8616 68.6506 69.3249 65.6201 67.4182L65.6223 66.7104C69.1139 68.8809 72.1144 72.9366 73.6393 77.1725L72.5626 76.5484Z"
        fill="#AEC0F2"
      />
      <path
        d="M64.8391 78.0214L65.5898 77.4166L74.7114 82.8707C74.7157 81.3736 74.4583 79.7973 73.9886 78.2273L73.7312 77.2236L73.639 77.1721C72.1141 72.9362 69.1136 68.8805 65.622 66.71L64.8713 66.2575C61.3797 64.4173 58.3706 64.9985 56.8285 67.4629L56.4339 68.2092L56.4747 68.1127C55.9985 69.1379 55.7326 70.4119 55.7283 71.9089L65.5898 77.4166L64.8391 78.0214L54.2334 71.9003C54.2527 64.9278 59.1835 62.1182 65.2488 65.6227C71.1983 69.0564 76.024 77.2836 76.1891 84.1768C76.1913 84.3098 76.1934 84.4449 76.1934 84.5779L64.8391 78.0214Z"
        fill="#E6E7E8"
      />
      <path
        d="M114.042 120.683V103.919L102.919 111.237L102.908 111.568L113.823 120.809L114.042 120.683Z"
        fill="#E6E7E8"
      />
      <path
        d="M102.748 127.104L114.042 120.683L102.909 111.568L102.748 115.988V127.104Z"
        fill="#E6E7E8"
      />
      <path
        d="M84.9071 121.268L84.9564 103.898L78.2112 100.003L78.1619 117.373L84.9071 121.268ZM77.3962 97.4038L85.7928 102.251L85.7328 123.874L77.334 119.025L77.3962 97.4038Z"
        fill="#AEC0F2"
      />
      <path
        d="M77.3346 119.025L77.3968 97.4037L85.7935 102.251L85.7334 123.874L77.3346 119.025ZM86.2911 100.276L76.5282 94.6392L76.4531 120.777L86.246 126.459L86.2911 100.276Z"
        fill="#D1D3D4"
      />
      <path
        d="M84.9566 103.898L84.9073 121.268L78.1621 117.373L78.2114 100.003L84.9566 103.898Z"
        fill="#081F5F"
      />
      <path
        d="M112.498 119.375L103.331 124.668L103.295 111.557L112.462 106.266L112.498 119.375ZM102.748 126.373L113.459 120.188L113.414 104.349L102.748 110.506V126.373Z"
        fill="#D1D3D4"
      />
      <path
        d="M111.562 108.08L111.592 118.602L104.231 122.853L104.199 112.329L111.562 108.08Z"
        fill="#081F5F"
      />
      <path
        d="M111.592 118.602L111.562 108.08L104.199 112.329L104.231 122.853L111.592 118.602ZM103.294 111.557L112.461 106.266L112.497 119.374L103.33 124.668L103.294 111.557Z"
        fill="#AEC0F2"
      />
      <path
        d="M131.654 110.72V93.957L120.531 101.275L120.521 101.605L131.435 110.845L131.654 110.72Z"
        fill="#E6E7E8"
      />
      <path
        d="M120.359 117.14L131.654 110.721L120.52 101.605L120.359 106.024V117.14Z"
        fill="#E6E7E8"
      />
      <path
        d="M130.112 109.412L120.945 114.703L120.908 101.594L130.075 96.299L130.112 109.412ZM120.359 116.41L131.07 110.225L131.027 94.3838L120.359 100.541V116.41Z"
        fill="#D1D3D4"
      />
      <path
        d="M129.177 98.1157L129.207 108.64L121.847 112.889L121.814 102.367L129.177 98.1157Z"
        fill="#081F5F"
      />
      <path
        d="M129.207 108.64L129.177 98.1159L121.814 102.367L121.846 112.889L129.207 108.64ZM120.909 101.595L130.076 96.2993L130.112 109.412L120.946 114.705L120.909 101.595Z"
        fill="#AEC0F2"
      />
      <path
        d="M47.7647 77.1917L49.0772 76.3359L48.9679 119.134L47.6553 119.988L47.7647 77.1917Z"
        fill="#E6E7E8"
      />
      <path
        d="M46.6104 76.4454L47.9229 75.5918L49.0768 76.336L47.7642 77.1918L46.6104 76.4454Z"
        fill="#86633F"
      />
      <path
        d="M47.7642 77.1917L47.6548 119.988L46.501 119.244L46.6104 76.4453L47.7642 77.1917Z"
        fill="#D1D3D4"
      />
      <path
        d="M59.9399 128.29L46.1342 120.264L46.1299 118.643L59.9356 126.667L59.9399 128.29Z"
        fill="#F1F2F2"
      />
      <path
        d="M59.9356 126.666L46.1299 118.643L55.26 113.371L69.0657 121.395L59.9356 126.666Z"
        fill="#AEC0F0"
      />
      <path
        d="M69.0667 121.395L69.071 123.019L59.9408 128.29L59.9365 126.667L69.0667 121.395Z"
        fill="#D9E8FF"
      />
      <path
        d="M62.6959 125.073L48.8902 117.047L48.8838 115.415L62.6916 123.441L62.6959 125.073Z"
        fill="#F1F2F2"
      />
      <path
        d="M62.6916 123.441L48.8838 115.415L55.2558 111.739L69.0615 119.763L62.6916 123.441Z"
        fill="#AEC0F0"
      />
      <path
        d="M69.0613 119.763L69.0656 121.395L62.6957 125.073L62.6914 123.441L69.0613 119.763Z"
        fill="#D9E8FF"
      />
      <path
        d="M65.4701 121.837L51.6644 113.811L51.6602 112.19L65.4659 120.213L65.4701 121.837Z"
        fill="#F1F2F2"
      />
      <path
        d="M65.4659 120.213L51.6602 112.19L55.2505 110.116L69.0562 118.141L65.4659 120.213Z"
        fill="#AEC0F0"
      />
      <path
        d="M69.0551 118.142L69.0616 119.763L65.4691 121.837L65.4648 120.213L69.0551 118.142Z"
        fill="#D9E8FF"
      />
      <path
        d="M69.1139 88.9984L70.4072 88.147L70.2999 130.817L69.0088 131.666L69.1139 88.9984Z"
        fill="#E6E7E8"
      />
      <path
        d="M67.9814 88.2541L69.2726 87.4048L70.4071 88.1469L69.1139 88.9983L67.9814 88.2541Z"
        fill="#86633F"
      />
      <path
        d="M69.1137 88.9986L69.0086 131.666L67.874 130.924L67.9813 88.2544L69.1137 88.9986Z"
        fill="#D1D3D4"
      />
      <path
        d="M76.4935 84.6104L76.4892 85.7985L69.6475 89.7449L69.6518 88.5567L76.4935 84.6104Z"
        fill="#E6E7E8"
      />
      <path
        d="M69.652 88.5569L69.6477 89.7451L46.2207 76.3212L46.2228 75.1309L69.652 88.5569Z"
        fill="#D1D3D4"
      />
      <path
        d="M76.4935 84.6107L69.6518 88.557L46.2227 75.1309L53.0644 71.1846L76.4935 84.6107Z"
        fill="#F1F2F2"
      />
      <path
        d="M93.9816 133.652L94.9682 133.08L94.9532 137.832L93.9688 138.407L93.9816 133.652Z"
        fill="#E6E7E8"
      />
      <path
        d="M93.9817 133.652L93.9688 138.407L93.334 138.04L93.3469 133.287L93.9817 133.652Z"
        fill="#D1D3D4"
      />
      <path
        d="M104.914 127.994L105.899 127.419L105.886 132.164L104.901 132.736L104.914 127.994Z"
        fill="#E6E7E8"
      />
      <path
        d="M104.279 127.627L105.266 127.055L105.899 127.419L104.914 127.994L104.279 127.627Z"
        fill="#AC7B09"
      />
      <path
        d="M104.914 127.994L104.901 132.736L104.264 132.372L104.279 127.627L104.914 127.994Z"
        fill="#D1D3D4"
      />
      <path
        d="M107.078 129.762L108.064 129.189L108.051 133.942L107.062 134.514L107.078 129.762Z"
        fill="#E6E7E8"
      />
      <path
        d="M106.435 129.388L107.421 128.815L108.065 129.189L107.078 129.761L106.435 129.388Z"
        fill="#AC7B09"
      />
      <path
        d="M107.078 129.761L107.063 134.514L106.42 134.143L106.435 129.388L107.078 129.761Z"
        fill="#D1D3D4"
      />
      <path
        d="M96.3957 135.679L97.3823 135.106L97.3694 139.848L96.3828 140.421L96.3957 135.679Z"
        fill="#E6E7E8"
      />
      <path
        d="M95.8779 135.777L96.8645 135.203L97.4994 135.569L96.5128 136.142L95.8779 135.777Z"
        fill="#AC7B09"
      />
      <path
        d="M96.3967 135.679L96.3839 140.421L95.749 140.054L95.7619 135.312L96.3967 135.679Z"
        fill="#D1D3D4"
      />
      <path
        d="M108.283 129.02L108.379 130.025L96.4246 136.955L96.3281 135.947L108.283 129.02Z"
        fill="#D1D3D4"
      />
      <path
        d="M96.3285 135.947L96.425 136.955L93.3473 134.315L93.2529 133.307L96.3285 135.947Z"
        fill="#D1D3D4"
      />
      <path
        d="M105.208 126.379L108.283 129.02L96.3285 135.947L93.2529 133.307L105.208 126.379Z"
        fill="#F1F2F2"
      />
      <path
        d="M113.329 101.249L114.155 100.666L122.174 133.172L121.348 133.755L113.329 101.249Z"
        fill="#E6E7E8"
      />
      <path
        d="M112.733 100.94L113.559 100.357L114.155 100.666L113.33 101.249L112.733 100.94Z"
        fill="#D1D3D4"
      />
      <path
        d="M113.33 101.249L121.349 133.755L120.753 133.446L112.733 100.94L113.33 101.249Z"
        fill="#D1D3D4"
      />
      <path
        d="M120.868 96.5394L121.692 95.9561L129.711 128.462L128.885 129.047L120.868 96.5394Z"
        fill="#E6E7E8"
      />
      <path
        d="M120.28 96.235L121.106 95.6538L121.692 95.9562L120.868 96.5396L120.28 96.235Z"
        fill="#D1D3D4"
      />
      <path
        d="M120.868 96.5394L128.885 129.047L128.299 128.743L120.28 96.2349L120.868 96.5394Z"
        fill="#D1D3D4"
      />
      <path
        d="M120.315 129.459L119.198 128.88L118.91 127.73L120.025 128.314L120.315 129.459Z"
        fill="#D1D3D4"
      />
      <path
        d="M120.025 128.314L118.91 127.731L127.691 121.575L128.806 122.156L120.025 128.314Z"
        fill="#D1D3D4"
      />
      <path
        d="M128.805 122.156L129.095 123.302L120.314 129.459L120.024 128.314L128.805 122.156Z"
        fill="#E6E7E8"
      />
      <path
        d="M118.781 123.299L117.666 122.718L117.376 121.571L118.491 122.152L118.781 123.299Z"
        fill="#D1D3D4"
      />
      <path
        d="M118.491 122.152L117.376 121.571L126.148 115.419L127.265 116.001L118.491 122.152Z"
        fill="#D1D3D4"
      />
      <path
        d="M127.265 116L127.555 117.148L118.781 123.299L118.491 122.152L127.265 116Z"
        fill="#E6E7E8"
      />
      <path
        d="M117.201 117.3L116.083 116.719L115.794 115.561L116.909 116.144L117.201 117.3Z"
        fill="#D1D3D4"
      />
      <path
        d="M116.909 116.144L115.794 115.561L124.566 109.412L125.683 109.991L116.909 116.144Z"
        fill="#D1D3D4"
      />
      <path
        d="M125.682 109.991L125.974 111.149L117.2 117.3L116.908 116.144L125.682 109.991Z"
        fill="#E6E7E8"
      />
      <path
        d="M116.399 111.819L115.286 111.237L114.996 110.09L116.109 110.671L116.399 111.819Z"
        fill="#D1D3D4"
      />
      <path
        d="M116.109 110.671L114.996 110.09L123.775 103.935L124.892 104.516L116.109 110.671Z"
        fill="#D1D3D4"
      />
      <path
        d="M124.892 104.516L125.182 105.661L116.399 111.818L116.109 110.671L124.892 104.516Z"
        fill="#E6E7E8"
      />
      <path
        d="M114.721 107.045L113.604 106.463L113.314 105.316L114.432 105.897L114.721 107.045Z"
        fill="#D1D3D4"
      />
      <path
        d="M114.432 105.897L113.314 105.316L122.097 99.1582L123.212 99.7416L114.432 105.897Z"
        fill="#D1D3D4"
      />
      <path
        d="M123.212 99.7412L123.502 100.887L114.721 107.044L114.432 105.897L123.212 99.7412Z"
        fill="#E6E7E8"
      />
      <path
        d="M117.109 64.139L93.5509 77.535C92.0109 78.4101 90.0528 77.8717 89.1777 76.3318L89.1456 76.2761C88.2705 74.7361 88.811 72.778 90.3488 71.9029L113.909 58.5069C115.447 57.6318 117.405 58.1723 118.28 59.7101L118.312 59.768C119.187 61.3058 118.649 63.2639 117.109 64.139Z"
        fill="#081F5F"
      />
      <path
        d="M90.752 73.7817L114.31 60.3878C115.85 59.5128 117.808 60.0511 118.681 61.591L118.713 61.6468C118.713 61.6489 118.715 61.6489 118.715 61.6511C118.775 61.0184 118.649 60.3621 118.312 59.768L118.28 59.7101C117.405 58.1723 115.447 57.6318 113.909 58.5069L90.3488 71.9029C88.811 72.778 88.2705 74.734 89.1456 76.2718C89.2399 75.2659 89.8061 74.3222 90.752 73.7817Z"
        fill="#3657B6"
      />
      <path
        d="M113.018 43.0709L109.691 41.2243C107.832 40.0232 105.202 40.124 102.286 41.8077C96.6492 45.0612 92.083 52.9946 92.083 59.5297C92.083 62.8862 93.2884 65.2197 95.2229 66.2556L95.2208 66.2599L98.3414 67.8706L98.3478 66.8304C99.5596 66.7188 100.889 66.2749 102.286 65.4684C107.92 62.217 112.488 54.2815 112.488 47.7486C112.488 46.0071 112.164 44.5443 111.581 43.3926L113.018 43.0709Z"
        fill="#F4C04B"
      />
      <path
        d="M116.565 50.0135C116.565 56.5464 111.999 64.4819 106.363 67.7355C100.728 70.9891 96.1602 68.3296 96.1602 61.7946C96.1602 55.2617 100.728 47.3261 106.363 44.0726C111.999 40.8211 116.565 43.4785 116.565 50.0135Z"
        fill="#FCE4B1"
      />
      <path
        d="M113.899 51.5515C113.899 56.3793 110.526 62.2409 106.363 64.6451C102.2 67.0472 98.8262 65.0827 98.8262 60.257C98.8262 55.4292 102.2 49.5697 106.363 47.1655C110.526 44.7612 113.899 46.728 113.899 51.5515Z"
        fill="#FCEED0"
      />
      <path
        d="M35.261 96.7219C35.261 105.796 31.424 113.151 31.424 113.151C31.424 113.151 27.585 105.796 27.585 96.7219C27.585 87.6475 31.424 80.291 31.424 80.291C31.424 80.291 35.261 87.6475 35.261 96.7219Z"
        fill="#89EFD7"
      />
      <path
        d="M23.9092 105.026C26.4485 111.61 31.2635 113.15 31.2635 113.15C31.2635 113.15 31.9648 104.016 29.4276 97.4316C26.8904 90.8472 22.0733 89.3052 22.0733 89.3052C22.0733 89.3052 21.3719 98.4418 23.9092 105.026Z"
        fill="#ADD6F2"
      />
      <path
        d="M38.7948 104.896C36.2575 111.482 31.4405 113.02 31.4405 113.02C31.4405 113.02 30.7391 103.885 33.2764 97.3032C35.8157 90.7188 40.6328 89.1768 40.6328 89.1768C40.6328 89.1768 41.3341 98.3134 38.7948 104.896Z"
        fill="#ADD6F2"
      />
      <path
        d="M24.2025 109.521C27.3875 113.695 31.4239 113.123 31.4239 113.123C31.4239 113.123 30.2893 105.785 27.1022 101.614C23.9151 97.4402 19.8809 98.0107 19.8809 98.0107C19.8809 98.0107 21.0133 105.35 24.2025 109.521Z"
        fill="#89EFD7"
      />
      <path
        d="M38.6452 109.521C35.4559 113.695 31.4238 113.123 31.4238 113.123C31.4238 113.123 32.5563 105.785 35.7433 101.614C38.9304 97.4402 42.9647 98.0107 42.9647 98.0107C42.9647 98.0107 41.8301 105.35 38.6452 109.521Z"
        fill="#89EFD7"
      />
      <path
        d="M160.398 96.8848C160.398 105.959 156.559 113.316 156.559 113.316C156.559 113.316 152.72 105.959 152.72 96.8848C152.72 87.8125 156.559 80.4561 156.559 80.4561C156.559 80.4561 160.398 87.8125 160.398 96.8848Z"
        fill="#89EFD7"
      />
      <path
        d="M149.044 105.189C151.581 111.773 156.398 113.315 156.398 113.315C156.398 113.315 157.102 104.179 154.564 97.5945C152.025 91.0101 147.208 89.4702 147.208 89.4702C147.208 89.4702 146.507 98.6047 149.044 105.189Z"
        fill="#ADD6F2"
      />
      <path
        d="M163.932 105.061C161.393 111.645 156.578 113.185 156.578 113.185C156.578 113.185 155.877 104.05 158.414 97.4661C160.951 90.8817 165.768 89.3418 165.768 89.3418C165.768 89.3418 166.47 98.4762 163.932 105.061Z"
        fill="#ADD6F2"
      />
      <path
        d="M149.336 109.687C152.525 113.86 156.56 113.288 156.56 113.288C156.56 113.288 155.427 105.951 152.238 101.777C149.053 97.6033 145.017 98.176 145.017 98.176C145.017 98.176 146.151 105.513 149.336 109.687Z"
        fill="#89EFD7"
      />
      <path
        d="M163.781 109.687C160.594 113.86 156.56 113.288 156.56 113.288C156.56 113.288 157.692 105.951 160.879 101.777C164.066 97.6033 168.103 98.176 168.103 98.176C168.103 98.176 166.968 105.513 163.781 109.687Z"
        fill="#89EFD7"
      />
    </svg>
  )
}
