import { useCallback, useEffect, useState } from 'react'
import cn from 'classnames'

import { Brand } from '@/components/smart'
import './style.scss'

interface IHeaderProps {
  isCongrats?: boolean
}

export const Header = ({ isCongrats }: IHeaderProps) => {
  const body = document.querySelector('body')
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    isCongrats ? body && body.classList.add('isCongrats') : null
  }, [isCongrats])

  const toTopHandler = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <header className={cn('header', { onScroll: scrollPosition > 0 })}>
      <div className="container">
        {isCongrats ? (
          <Brand
            onClick={toTopHandler}
            source={{
              svg: {
                icon: 'a4uWhite',
                viewBox: '0 0 151 38',
              },
            }}
          />
        ) : scrollPosition > 0 ? (
          <>
            <Brand
              onClick={toTopHandler}
              source={{
                svg: {
                  icon: 'a4uWhite',
                  viewBox: '0 0 151 38',
                },
              }}
            />
          </>
        ) : (
          <>
            <Brand
              onClick={toTopHandler}
              source={{
                svg: {
                  icon: 'a4u',
                  viewBox: '0 0 151 38',
                },
              }}
            />
          </>
        )}
      </div>
    </header>
  )
}
