export const BusinessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="241"
      height="218"
      viewBox="0 0 241 218"
      fill="none"
    >
      <path
        d="M219.671 83.9211C198.336 48.078 157.44 43.685 103.647 50.0761C49.854 56.4671 12.2903 90.4669 19.7458 126.015C27.199 161.564 68.3833 193.132 130.643 178.813C164.304 171.073 239.085 116.532 219.671 83.9211Z"
        fill="#CEE6FA"
      />
      <path
        d="M86.3252 112.379C86.3374 112.553 86.3558 112.702 86.3823 112.836C86.1926 112.895 85.9948 113.077 85.9948 113.287L85.9683 113.782H85.54V113.287C85.54 112.846 85.9112 112.471 86.3252 112.379Z"
        fill="#193564"
      />
      <path
        d="M89.2543 112.496C89.2543 112.496 89.2848 112.451 89.3215 112.381C89.7641 112.449 90.1434 112.828 90.1434 113.287V113.782H89.6907L89.6886 113.287C89.6886 113.052 89.4745 112.859 89.2461 112.828C89.2563 112.644 89.2543 112.496 89.2543 112.496Z"
        fill="#193564"
      />
      <path
        d="M99.7297 87.7609C99.7297 87.7609 99.6359 88.3808 99.2586 88.8866L101.3 90.8178L101.398 88.6113C101.398 88.6113 102.632 89.0354 103.154 88.6602C103.676 88.287 103.953 87.3897 103.753 86.7698C103.753 86.7698 103.539 86.4863 103.998 86.4108C104.457 86.3354 104.049 85.8541 103.737 85.5972C103.425 85.3382 103.413 84.4347 103.413 84.4347L101.557 84.084L98.9629 85.7256C98.9629 85.7256 99.6073 86.8289 99.7297 87.7609Z"
        fill="white"
      />
      <path
        d="M102.242 89.0128L102.071 88.0747L100.837 88.8741L101.3 90.8176L102.197 90.2405L102.242 89.0128Z"
        fill="white"
      />
      <path
        d="M88.1194 110.038C88.152 110.631 89.1615 111.178 89.4082 111.58C89.655 111.984 89.2961 112.493 89.2961 112.493C89.2961 112.493 89.3002 113.013 89.2206 113.295C89.1431 113.576 88.8291 113.607 88.5191 113.342C88.2112 113.077 88.0745 113.529 87.6748 113.452C87.2731 113.374 87.1997 113.158 86.6939 113.209C85.8089 113.299 86.5573 109.889 86.5573 109.889L88.1194 110.038Z"
        fill="white"
      />
      <path
        d="M118.337 100.69C118.929 100.747 119.622 99.8311 120.056 99.6476C120.491 99.4641 120.944 99.8944 120.944 99.8944C120.944 99.8944 121.46 99.9678 121.727 100.088C121.994 100.206 121.977 100.52 121.67 100.788C121.36 101.053 121.788 101.255 121.651 101.638C121.515 102.023 121.29 102.062 121.266 102.57C121.221 103.459 117.958 102.207 117.958 102.207L118.337 100.69Z"
        fill="white"
      />
      <path
        d="M112.314 125.751L112.176 127.709L114.682 127.776L114.835 125.631L112.314 125.751Z"
        fill="#E4E8FF"
      />
      <path
        d="M99.0828 135.219L98.7667 137.699L95.8994 137.999L96.2441 134.971L99.0828 135.219Z"
        fill="#E4E8FF"
      />
      <path
        d="M99.2584 88.8867C99.2584 88.8867 99.9885 89.5393 101.214 90.1266C101.214 90.1266 101.905 89.6535 102.242 89.0132L103.211 89.5067C103.211 89.5067 104.669 94.4907 104.661 97.6435C104.652 100.798 106.402 105.658 106.692 109.449C106.692 109.449 105.021 113.057 101.375 113.057C97.7269 113.057 94.5537 108.941 94.5537 108.941C94.5537 108.941 95.2879 105.26 95.6916 103.672C96.0934 102.083 96.3259 99.2872 95.6916 97.6537C95.0554 96.0202 97.5699 91.7846 98.3774 89.7086L99.2584 88.8867Z"
        fill="#E4E8FF"
      />
      <path
        d="M86.2617 109.673L86.2638 110.297L88.5029 110.407L88.5274 109.692L86.2617 109.673Z"
        fill="#E4E8FF"
      />
      <path
        d="M117.698 102.47L118.314 102.56L118.759 100.365L118.053 100.233L117.698 102.47Z"
        fill="#E4E8FF"
      />
      <path
        d="M52.0381 128.233C52.2706 127.22 53.1781 126.465 54.265 126.465C54.4628 126.465 54.6566 126.49 54.8401 126.538C55.3744 125.394 56.5368 124.601 57.8848 124.601C58.2315 124.601 58.568 124.656 58.884 124.754C59.61 123.555 60.9274 122.751 62.4324 122.751C64.225 122.751 65.7504 123.885 66.3275 125.476C66.7374 125.248 67.2085 125.117 67.7102 125.117C69.2784 125.117 70.5509 126.39 70.5509 127.956C70.5509 128.05 70.5469 128.141 70.5367 128.233H52.0381Z"
        fill="#E4E8FF"
      />
      <path
        d="M99.2585 88.8867C99.2585 88.8867 95.235 89.9227 94.7272 90.3734C94.2194 90.8241 89.5025 96.528 88.9682 97.5273C88.4339 98.5245 87.5692 100.011 86.9656 102.852C86.4496 105.283 85.9316 109.867 85.9316 109.867L88.7744 110.016L91.0279 100.972L94.668 97.407C94.668 97.407 95.6102 105.509 94.1623 108.735C94.1623 108.735 97.4354 110.662 101.989 110.448C101.989 110.448 101.408 100.78 100.292 98.0371C99.177 95.2942 99.0974 92.0232 99.2585 88.8867Z"
        fill="#2563F6"
      />
      <path
        d="M102.242 89.0126C102.242 89.0126 103.195 95.1224 102.968 98.4852C102.74 101.848 102.666 103.019 103.146 105.519C103.625 108.019 103.984 110.27 103.984 110.27C103.984 110.27 105.831 109.967 107.467 109.067C107.467 109.067 107.067 105.237 106.121 102.98C104.551 99.2337 105.124 95.8606 105.124 95.8606C105.124 95.8606 107.294 99.2459 108.69 100.614C109.329 101.238 111.268 101.579 112.449 101.789C114.288 102.115 117.747 102.745 117.747 102.745L118.389 100.18C118.389 100.18 112.384 98.7055 111.174 98.1018L105.67 89.8324C105.67 89.8284 104.755 88.8923 102.242 89.0126Z"
        fill="#2563F6"
      />
      <path
        d="M92.7068 121.35H82.9793C82.5613 121.35 82.2207 121.009 82.2207 120.591V114.885H93.4675V120.591C93.4675 121.009 93.1269 121.35 92.7068 121.35Z"
        fill="#2563F6"
      />
      <mask
        id="mask0_304_9797"
        maskUnits="userSpaceOnUse"
        x="92"
        y="141"
        width="15"
        height="34"
      >
        <path
          d="M92.6377 141.131H106.733V174.904H92.6377V141.131Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_304_9797)">
        <path
          d="M92.9072 141.323H106.693V174.858H92.9072V141.323Z"
          fill="#2563F6"
        />
      </g>
      <mask
        id="mask1_304_9797"
        maskUnits="userSpaceOnUse"
        x="74"
        y="147"
        width="15"
        height="28"
      >
        <path
          d="M74.3652 147.396H88.983V174.905H74.3652V147.396Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_304_9797)">
        <path
          d="M74.7402 147.632H88.526V174.857H74.7402V147.632Z"
          fill="#2563F6"
        />
      </g>
      <mask
        id="mask2_304_9797"
        maskUnits="userSpaceOnUse"
        x="56"
        y="152"
        width="15"
        height="23"
      >
        <path
          d="M56.6152 152.617H70.7109V174.904H56.6152V152.617Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_304_9797)">
        <path
          d="M56.8867 153.082H70.6725V174.857H56.8867V153.082Z"
          fill="#2563F6"
        />
      </g>
      <mask
        id="mask3_304_9797"
        maskUnits="userSpaceOnUse"
        x="110"
        y="131"
        width="16"
        height="44"
      >
        <path
          d="M110.909 131.212H125.005V174.904H110.909V131.212Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_304_9797)">
        <path
          d="M111.199 131.306H124.983V174.857H111.199V131.306Z"
          fill="#2563F6"
        />
      </g>
      <mask
        id="mask4_304_9797"
        maskUnits="userSpaceOnUse"
        x="129"
        y="115"
        width="15"
        height="60"
      >
        <path
          d="M129.182 115.55H143.799V174.904H129.182V115.55Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_304_9797)">
        <path
          d="M129.679 115.856H143.462V174.858H129.679V115.856Z"
          fill="#2563F6"
        />
      </g>
      <mask
        id="mask5_304_9797"
        maskUnits="userSpaceOnUse"
        x="147"
        y="93"
        width="16"
        height="82"
      >
        <path
          d="M147.976 93.6235H162.071V174.904H147.976V93.6235Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_304_9797)">
        <path
          d="M148.157 94.123H161.943V174.857H148.157V94.123Z"
          fill="#2563F6"
        />
      </g>
      <mask
        id="mask6_304_9797"
        maskUnits="userSpaceOnUse"
        x="166"
        y="74"
        width="15"
        height="101"
      >
        <path
          d="M166.248 74.8291H180.344V174.904H166.248V74.8291Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_304_9797)">
        <path
          d="M166.323 75.1953H180.109V174.856H166.323V75.1953Z"
          fill="#2563F6"
        />
      </g>
      <path
        d="M101.214 90.1265C101.214 90.1265 100.957 90.6404 100.509 90.9034C100.509 90.9034 101.127 91.4785 101.384 91.5091C101.639 91.5397 101.922 90.9034 101.922 90.9034C101.922 90.9034 101.796 90.3936 101.214 90.1265Z"
        fill="#89EFD7"
      />
      <path
        d="M101.178 90.9727C101.178 90.9727 100.586 93.4463 98.4207 94.8637C96.255 96.281 95.0375 97.4108 95.009 101.771C95.009 101.771 95.1884 104.642 93.4795 106.057L93.5835 109.439C93.5835 109.439 95.8431 108.482 96.7159 107.02C97.5887 105.56 97.3644 101.769 97.3277 100.959C97.291 100.152 97.242 97.7738 99.11 96.1178C100.976 94.4619 101.586 92.9712 101.704 91.0522L101.178 90.9727Z"
        fill="#89EFD7"
      />
      <path
        d="M87.8428 119.18C88.0876 119.18 88.2854 118.983 88.2854 118.74V118.369C88.2854 118.124 88.0876 117.928 87.8428 117.928C87.6002 117.928 87.4023 118.126 87.4023 118.369V118.74C87.4023 118.985 87.6002 119.18 87.8428 119.18Z"
        fill="#89EFD7"
      />
      <mask
        id="mask7_304_9797"
        maskUnits="userSpaceOnUse"
        x="38"
        y="66"
        width="176"
        height="109"
      >
        <path
          d="M38.9971 66.9985H213.578V174.905H38.9971V66.9985Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask7_304_9797)">
        <path
          d="M213.536 67.1045H182.304L187.3 75.7512L170.223 84.2877L152.931 122.798L133.342 129.703L115.347 149.807L97.0706 146.456L80.4401 160.45L62.7266 158.029L39.04 174.858H52.1936L64.8332 166.211L82.8261 168.668L99.2894 154.815L118.255 158.29L137.843 136.412L158.701 129.057L176.21 90.061L191.287 82.6502L197.919 94.1233L213.536 67.1045Z"
          fill="#89EFD7"
        />
      </g>
      <path
        d="M102.242 89.0125C102.242 89.0125 102.809 92.6527 102.964 95.8605C103.515 93.7865 104.369 91.8492 104.369 91.8492C104.065 91.4393 103.235 90.9825 103.235 90.9825L103.957 90.4033C103.706 89.9017 103.392 89.3959 103.139 89.0146C102.866 89.0003 102.566 88.9962 102.242 89.0125Z"
        fill="#193564"
      />
      <path
        d="M99.7295 87.7605C99.7295 87.7605 99.6398 87.3731 99.0198 86.8612C98.3999 86.3514 97.6372 85.0646 98.0573 84.0572C98.0573 84.0572 98.406 83.2577 99.287 83.0803C99.287 83.0803 99.6744 81.9036 100.68 81.9077C101.685 81.9118 102.311 82.3972 102.754 82.442C103.198 82.4869 104.324 82.6745 104.251 83.5494C104.175 84.4263 103.039 85.3093 102.052 84.6282C102.052 84.6282 101.608 84.3549 101.665 85.0931C101.724 85.8334 101.614 86.0088 101.614 86.0088C101.614 86.0088 101.318 85.2971 100.878 85.2522C100.433 85.2073 100.194 85.4949 100.213 85.8416C100.239 86.2739 100.003 87.3894 99.7295 87.7605Z"
        fill="#193564"
      />
      <path
        d="M99.4404 95.814C99.5465 95.9853 99.6484 96.1546 99.7422 96.3259C99.6872 96.1056 99.6403 95.8854 99.5934 95.6631C99.5444 95.7141 99.4935 95.763 99.4404 95.814Z"
        fill="#193564"
      />
      <path
        d="M97.0117 92.6248C97.0117 92.6248 97.8417 93.5425 98.6758 94.6865C98.9226 94.505 99.1469 94.3113 99.3508 94.1094C99.155 92.4025 99.1693 90.6242 99.259 88.8867C99.259 88.8867 98.5861 89.0601 97.7561 89.2946C97.5338 89.7208 97.2524 90.2673 97.0321 90.6874L98.1721 91.2992C98.1721 91.2992 97.5093 91.7764 97.0117 92.6248Z"
        fill="#193564"
      />
      <path
        d="M92.7068 113.603H82.9793C82.5613 113.603 82.2207 113.943 82.2207 114.361V115.008C82.2207 115.426 82.4511 115.809 82.8203 116.009L87.3047 118.417C87.3373 118.436 87.37 118.448 87.4026 118.462V118.368C87.4026 118.126 87.6004 117.928 87.8431 117.928C88.0878 117.928 88.2856 118.126 88.2856 118.368V118.462C88.3182 118.448 88.3509 118.436 88.3815 118.417L92.8679 116.009C93.235 115.809 93.4655 115.426 93.4655 115.008V114.361C93.4675 113.943 93.1269 113.603 92.7068 113.603Z"
        fill="#193564"
      />
      <path
        d="M103.765 141.431L96.3829 141.437C95.7385 141.439 95.3347 140.764 95.3898 140.487L95.5651 137.911C95.7161 137.344 96.856 137.834 97.6289 137.31C98.6771 136.881 98.7404 136.765 99.0809 136.843C99.6805 136.969 99.6682 137.342 99.5704 137.783C99.2869 138.499 100.213 139.112 100.79 139.114C103.147 139.125 105.242 140.781 103.765 141.431Z"
        fill="#193564"
      />
      <path
        d="M118.7 131.465L111.849 131.483C111.57 131.483 111.126 131.004 111.207 130.776L111.947 127.554C112.145 127.091 112.975 127.684 113.656 127.356C114.539 127.148 114.607 127.058 114.864 127.176C115.316 127.378 115.255 127.696 115.114 128.059C114.79 128.632 115.422 129.411 115.883 129.391C118.184 129.285 119.952 131.135 118.7 131.465Z"
        fill="#193564"
      />
      <path
        d="M116.891 111.651C115.573 110.267 110.159 109.773 107.467 109.067C105.831 109.967 103.984 110.271 103.984 110.271C103.984 110.271 103.811 109.188 103.545 107.691C103.001 107.805 102.308 107.956 101.814 108.044C101.869 108.709 101.91 109.271 101.938 109.689C101.947 109.781 101.953 109.867 101.957 109.944C101.959 109.965 101.959 109.989 101.961 110.01C101.971 110.173 101.979 110.289 101.985 110.362C101.987 110.413 101.989 110.448 101.989 110.448C101.492 110.473 101.013 110.466 100.55 110.444C100.395 110.436 100.252 110.42 100.101 110.407C99.7972 110.383 99.4953 110.356 99.2098 110.318C99.0487 110.295 98.8958 110.271 98.7408 110.244C98.4798 110.199 98.2269 110.154 97.9842 110.101C97.8415 110.071 97.7007 110.038 97.5661 110.006C97.3275 109.946 97.1012 109.887 96.885 109.824C95.8144 109.512 94.9987 109.157 94.5561 108.941H94.5541C94.1789 110.446 94.8865 113.024 96.7667 114.696C96.987 118.565 97.4418 122.252 97.4418 122.252C95.3861 126.318 95.6431 135.992 95.6431 135.992L99.3016 136.092C99.581 133.637 102.277 124.44 102.306 122.144C102.318 121.075 103.178 118.693 103.692 116.24C109.227 116.019 112.351 115.293 112.351 115.293L111.69 126.797L115.473 126.149C115.473 126.149 118.567 113.417 116.891 111.651Z"
        fill="#193564"
      />
      <path
        d="M101.753 90.5625C101.885 90.7481 101.922 90.901 101.922 90.901C101.922 90.901 101.82 91.1315 101.683 91.3068C101.526 93.1035 100.89 94.5351 99.1099 96.1176C97.2419 97.7715 97.2908 100.149 97.3275 100.959C97.3642 101.769 97.5865 105.56 96.7157 107.02C96.2202 107.85 95.276 108.519 94.552 108.941C94.45 109.338 94.4541 109.82 94.4541 109.82C94.4541 109.82 96.4914 108.798 97.3234 107.313C98.1534 105.829 97.8231 102.046 97.7639 101.238C97.7068 100.431 97.5906 98.0549 99.4076 96.346C101.145 94.7166 101.741 93.2666 101.847 91.4659C101.979 91.2865 102.075 91.056 102.075 91.056C102.075 91.056 102.004 90.8011 101.753 90.5625Z"
        fill="#193564"
        fillOpacity="0.2"
      />
      <path
        d="M102.202 88.7946L102.159 88.7864C102.159 88.7864 101.315 88.611 100.636 88.1216C100.636 88.1216 101.144 88.6763 102.243 89.0128L102.202 88.7946Z"
        fill="#193564"
        fillOpacity="0.2"
      />
      <path
        d="M105.436 93.0405C105.436 93.9786 105.124 95.8609 105.124 95.8609C105.124 95.8609 105.24 96.0444 105.436 96.3381C105.436 96.334 105.436 96.3299 105.436 96.3259C105.554 94.6822 105.436 93.0405 105.436 93.0405Z"
        fill="#193564"
        fillOpacity="0.2"
      />
      <path
        d="M94.7291 98.0065C96.3076 95.8611 96.7114 94.5254 96.7114 94.5254C96.0588 95.8632 94.668 97.409 94.668 97.409C94.668 97.409 94.6945 97.6272 94.7291 98.0065Z"
        fill="#193564"
        fillOpacity="0.2"
      />
    </svg>
  )
}
