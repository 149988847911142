export const SingleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="93"
      viewBox="0 0 95 93"
      fill="none"
    >
      <path
        d="M76.0187 73.9278L94.0453 18.4542C97.3559 8.26876 91.7801 0 81.5946 0H48.4562C38.2707 0 27.3249 8.25292 24.0143 18.4542L0 92.3662H51.5768C61.7623 92.3662 72.7081 84.1133 76.0187 73.9119V73.9278Z"
        fill="#F4C04B"
      />
    </svg>
  )
}
