export const DivorcedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="142"
      height="49"
      viewBox="0 0 142 49"
      fill="none"
    >
      <path
        d="M134.993 0.00827975H25.3112C19.9873 0.00827975 14.266 4.32203 12.5355 9.65419L0 48.2296H119.32C124.643 48.2296 130.365 43.9158 132.095 38.5836L141.501 9.64591C143.231 4.32203 140.317 0 134.993 0V0.00827975Z"
        fill="#6A4AE3"
      />
    </svg>
  )
}
