import cn from 'classnames'
import { isMobile } from 'react-device-detect'

import { useCallback } from 'react'
import './SvgImgComponent.css'; // Adjust the path as needed

const SvgImgComponent = () => (
  <div className="main-container">
  <div className="image-container">
    <img src={`/Untitled design.svg.svg`} alt="Icon" width={150} height={150} />
  </div>
  </div>
);
interface IBrand {
  source: {
    img?: {
      filename: string
      alt?: 'logo'
    }
    svg?: {
      hasSlogan?: boolean
      icon: string
      viewBox: string
    }
  }
  onClick?: () => void
}
export const Brand = ({ source, onClick }: IBrand) => {
  const clickHandler = useCallback(() => {
    onClick && onClick()
  }, [onClick])

  return (
    <div
      className={cn('brand', { 'brand--mobile': isMobile })}
      onClick={clickHandler}
    >
    <SvgImgComponent/>
    </div>
  )
}
