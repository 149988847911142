export const WidowedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="117"
      height="124"
      viewBox="0 0 117 124"
      fill="none"
    >
      <path
        d="M110.163 49.2535H59.6219L73.0017 8.07555C74.2946 4.09077 71.3273 0 67.1305 0H10.5063C7.83031 0 5.46169 1.72215 4.63506 4.26563L0.305841 17.5977C-0.987095 21.5825 1.9803 25.6733 6.17704 25.6733H31.792C35.9835 25.6733 38.9562 29.764 37.6632 33.7488L26.9594 66.6975C25.6665 70.677 28.6233 74.7625 32.8094 74.773L74.8563 74.9002C79.0425 74.9108 81.9993 78.9963 80.7063 82.9758L70.0025 115.924C68.7096 119.909 71.677 124 75.8684 124H89.8894C92.5653 124 94.9339 122.278 95.7606 119.734L116.04 57.3237C117.338 53.3389 114.365 49.2482 110.174 49.2482L110.163 49.2535Z"
        fill="#2563F6"
      />
    </svg>
  )
}
