import { useTranslation } from 'react-i18next'

interface IRenderTitleProps {
  titleKey: string
  descriptionKey?: string
}
export const RenderTitle = ({
  titleKey,
  descriptionKey,
}: IRenderTitleProps) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="title">{t(titleKey)}</div>
      {descriptionKey && <div className="description">{t(descriptionKey)}</div>}
    </>
  )
}
