export enum EUserApiPath {
  // Authentication
  login = 'login',
  verifyCode = 'verifyCode',
  // update loan request
  update = 'update',
  // old
  signUp = 'signup',
  refresh = 'refresh',
  // User Management
  verify = 'verify',
  forgotPassword = 'forgetPasword',
  confirmForgotPassword = 'confirmForgotPassword',
  delete = 'delete',
  // Profile
  profile = 'profile',
  updateProfile = 'updateProfile',
  // Lead
  addLead = 'addLead',
  //
  card = 'card',
}

enum EUserApiTokenType {
  bearer = 'Bearer',
}

interface ISignUpApiCallPayload {
  username: string // email or phone
  password: string
}
interface IVerifyApiCallPayload {
  username: string // email or phone
  code: string
}

export interface IRefreshTokenApiCallPayload {
  refreshToken: string
  username: string //  phone
}

interface IAddLeadApiCallPayload {
  companyName: string
  contactFullName: string
  email: string
  phone: string
  proposition: string
}

export interface ISaveUserCardPayload {
  cardNumber: string
  cvv: string
  cardDate: string
}

// Payload queries
export type TSignUpApiCallPayload = ISignUpApiCallPayload
export type TDeleteApiCallPayload = TSignUpApiCallPayload
export type TVerifyApiCallPayload = IVerifyApiCallPayload
export type TLoginApiCallPayload = Pick<
  ISignUpApiCallPayload & IVerifyApiCallPayload,
  'username' | 'password'
>

// export type TLoginApiCallPayload = Pick<
//   ISignUpApiCallPayload & IVerifyApiCallPayload,
//   'username' | 'password'
// >
export type TAddLeadApiCallPayload = IAddLeadApiCallPayload

// ----------------- NEEDED -----------------
// Response queries
export type TLoginApiCallResponse = {
  session: string
  codeVerifier: string
  username: string
}

export type TVerifyLoginApiCallPayload = TLoginApiCallResponse & {
  code: string
}

export type TUpdateLoanRequestPayload = {
  agentId?: string
  parentAgentId?: string
  topAgentID?: string
  firstName?: string
  lastName?: string
  reason?: ELoanReason | string
  carType?: string
  carYear?: string
  carValue?: string
  carBrand?: string
  commition?: string | number
  requestedLoan?: string
  monthlyReturn?: string
  employmentType?: EEmplymentType | string
  maritalStatus?: EMaritalStatus | string
  partnerEmploymentType?: EEmplymentType | string
  salary?: string
  alimony?: string
  pension?: string
  allowance?: string
  rent?: string

  propertyOwner?: string
  rentPayment?: string
  bankBlackList?: EIgnoredBank[] | string[]
}

interface ILoginApiCallResponse {
  AccessToken: string
  ExpiresIn: number
  TokenType: EUserApiTokenType
  RefreshToken: string
  IdToken: string
}

export type TVerifyLoginApiCallResponse = ILoginApiCallResponse & {
  username: string
}

export enum ELoanReason {
  CAR = 'CAR',
  LODGING = 'LANDING',
  LOANS = 'LOAN_REDUCTION',
  OTHER = 'OTHER',
  BUSINESS = 'BUSINESS',
}

export enum EEmplymentType {
  CONTRACT = 'CONTRACT',
  FREELANCER = 'FREELANCER',
  BETWEEN_JOBS = 'BETWEEN_JOBS',
  PENSION = 'PENSION',
}

export enum EMaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}

export enum EIgnoredBank {
  DISCONT = 'DISCONT',
  LEUMI = 'LEUMI',
  MIZRACHI = 'MIZRACHI',
  POALIM = 'POALIM',
  BENLEUMI = 'BENLEUMI',
  YAHAV = 'YAHAV',
  IGUD = 'IGUD',
  ONEZERO = 'ONEZERO',
}

export * from './editProfile'
