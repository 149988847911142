import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import { EEmplymentType, TUpdateLoanRequestPayload } from '@/core/api/types'

import { Button, EButtonType, RadioButtonGroup } from '@/components/ui'
import { EPictureExt, Picture, RenderTitle } from '@/components/smart'

import { EQuizStep } from '@/pages'

interface IEmploymentProps {
  setStep: Dispatch<SetStateAction<EQuizStep | undefined>>
  setUpdatePayload: Dispatch<SetStateAction<TUpdateLoanRequestPayload>>
  initialData: TUpdateLoanRequestPayload
}

export const Employment = ({
  setStep,
  setUpdatePayload,
  initialData,
}: IEmploymentProps) => {
  const { t } = useTranslation()

  const [employmentType, setEmploymentType] = useState(
    initialData.employmentType && initialData.employmentType !== 'N/A'
      ? initialData.employmentType
      : ('' as EEmplymentType),
  )

  const RadioChildren = ({
    text,
    image,
  }: {
    text: string
    image: {
      name: string
      alt: string
    }
  }) => {
    return (
      <div className="radio-children">
        <div className="radio-children__text">{text}</div>
        <div className="img-container">
          <Picture
            img={{
              name: image.name,
              path: 'employment',
              ext: EPictureExt.png,
            }}
          />
        </div>
      </div>
    )
  }

  const radioList = [
    {
      value: EEmplymentType.FREELANCER,
      name: 'button-types',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('employmentTypePage.type.FREELANCER')}
          image={{ name: 'freelancer', alt: 'freelancer' }}
        />
      ),
    },
    {
      value: EEmplymentType.CONTRACT,
      name: 'button-types',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('employmentTypePage.type.CONTRACT')}
          image={{ name: 'contract', alt: 'contract' }}
        />
      ),
    },
    {
      value: EEmplymentType.BETWEEN_JOBS,
      name: 'button-types',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('employmentTypePage.type.BETWEEN_JOBS')}
          image={{ name: 'beetween_jobs', alt: 'beetween_jobs' }}
        />
      ),
    },
    {
      value: EEmplymentType.PENSION,
      name: 'button-types',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('employmentTypePage.type.PENSION')}
          image={{ name: 'pension', alt: 'pension' }}
        />
      ),
    },
  ]

  const radioGroupHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEmploymentType(event.target.value as EEmplymentType)
  }

  const updateProfileHandler = () => {
    const payload = {
      employmentType: employmentType,
    } as TUpdateLoanRequestPayload
    ReactGA.event({
      category: 'Button Click',
      action: 'employmentType',
      label: 'Quiz Page',
    })
    ReactGA.send({
      fieldsObject: payload,
    })
    setUpdatePayload((prev) => ({
      ...prev,
      ...payload,
    }))

    setStep(EQuizStep.GOAL)
  }

  return (
    <>
      <section className={'employment'}>
        <RenderTitle titleKey={'employmentTypePage.title'} />
        <form>
          <RadioButtonGroup
            label=""
            options={radioList}
            onChange={radioGroupHandler}
            selectedValue={employmentType}
          />
        </form>

        <Button
          type={EButtonType.button}
          disabled={!employmentType}
          onClick={updateProfileHandler}
        >
          {t('employmentTypePage.next')}
        </Button>
      </section>
    </>
  )
}
