/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMask, IMaskInput } from 'react-imask'
import ReactGA from 'react-ga'

import { useAuth } from '@/core/context'
import { EMaritalStatus, TUpdateLoanRequestPayload } from '@/core/api/types'
import { getLoanRequestApiCall, refreshTokenApiCall } from '@/core/api/calls'

import { Button, EButtonType } from '@/components/ui'
import { BackPage, RangeComponent, RenderTitle } from '@/components/smart'

import { EQuizStep } from '@/pages'
import styles from './income.module.scss'

interface IIncomeProps {
  setStep: Dispatch<SetStateAction<EQuizStep | undefined>>
  setUpdatePayload: Dispatch<SetStateAction<TUpdateLoanRequestPayload>>
  initialData: TUpdateLoanRequestPayload
  loanRequestUpdater: (payload: TUpdateLoanRequestPayload) => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function cleanObject(obj: Partial<any>) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      if (
        (typeof value !== 'string' && typeof value !== 'number') ||
        value === 'N/A'
      ) {
        delete obj[key]
      }
    }
  }
  return obj
}

const configBlocks = {
  inputMask: {
    mask: '₪{ }VALUE',
    blocks: {
      VALUE: {
        mask: IMask.MaskedNumber,
        min: 0,
        max: 100000,
        thousandsSeparator: ',',
      },
    },
  },
}

export const Income = ({
  setStep,
  setUpdatePayload,
  initialData,
  loanRequestUpdater,
}: IIncomeProps) => {
  const { user, updateAuthCredentials } = useAuth()
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoanLoading, setLoanLoading] = useState<boolean>(false)

  const [salary, setSalaryValue] = useState<number>(
    initialData.salary && initialData.salary !== 'N/A'
      ? parseInt(initialData.salary, 10)
      : 0,
  )
  const [input, setInput] = useState({
    alimony: '',
    pension: '',
    allowance: '',
    rent: '',
  })

  const salaryChangeRangeHandler = (value: number) => {
    setSalaryValue(value)
  }

  const onInputChange = ({ name, value }: { name: string; value: string }) => {
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const updateProfileHandler = async () => {
    const payload = {
      ...initialData,
      salary: salary.toString(),
      alimony: input.alimony,
      pension: input.pension,
      allowance: input.allowance,
      rent: input.rent,
    }

    try {
      setLoading(true)

      ReactGA.event({
        category: 'Button Click',
        action: 'budget',
        label: 'Quiz Page',
      })
      ReactGA.send({
        fieldsObject: payload,
      })
      await loanRequestUpdater(payload)
      let response = await getLoanRequest()
      console.log(response)

      if (Object.prototype.hasOwnProperty.call(response, 'error')) {
        response = await getLoanRequest()
      } else {
        setUpdatePayload((prev) => ({
          ...prev,
          ...response,
        }))

        setStep(EQuizStep.WHATSAPP)
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }

    // setUpdatePayload((prev) => ({
    //   ...prev,
    //   ...payload,
    // }))

    // setStep(EQuizStep.BANK_IGNORE)
  }

  const getLoanRequest = async () => {
    try {
      if (user) {
        setLoanLoading(true)
        const response = await getLoanRequestApiCall(user)
        return response
      }
    } catch (error: any) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === 'Failed to fetch') {
          const response =
            user &&
            (await refreshTokenApiCall(
              {
                refreshToken: user.RefreshToken,
                username: user.username,
              },
              user,
            ))
          console.log('refreshToken:', response)
          updateAuthCredentials({ ...user, ...response })
        }
      }
    } finally {
      setLoanLoading(false)
    }
  }

  return (
    <>
      <BackPage
        onNavigate={() => {
          setUpdatePayload((prev) => ({
            ...prev,
            salary: 'N/A',
            maritalStatus: initialData.maritalStatus,
            partnerEmploymentType: 'N/A',
          }))
          setStep(EQuizStep.MERITAL_STATUS)
        }}
      />
      <section className={styles.income}>
        <div>
          <RenderTitle
            titleKey={
              initialData.maritalStatus === EMaritalStatus.MARRIED
                ? 'incomePage.title.married'
                : 'incomePage.title.general'
            }
            descriptionKey={
              initialData.maritalStatus === EMaritalStatus.MARRIED
                ? 'incomePage.description.married'
                : 'incomePage.description.general'
            }
          />
        </div>

        <form className={styles.incomeForm}>
          <div className={styles.incomeSalary}>
            <div className={styles.incomeSalaryInfo}>
              <div className={styles.incomeSalaryInfoLabel}>
                {initialData.maritalStatus === EMaritalStatus.MARRIED
                  ? t('incomePage.salary.info.maried')
                  : t('incomePage.salary.info.general')}
              </div>
              <div className={styles.incomeSalaryInfoValue}>
                {new Intl.NumberFormat('he').format(salary)} ₪
              </div>
            </div>

            <div className={styles.incomeRange}>
              <RangeComponent
                initValue={salary}
                min={0}
                max={100000}
                onChange={salaryChangeRangeHandler}
                color={'#3ae'}
                step={500}
                isDisabled={isLoading || isLoanLoading}
              />
            </div>

            <div className={styles.incomeSalaryDescription}>
              {t('incomePage.salary.description')}
            </div>
          </div>

          <fieldset className={styles.incomeFieldset}>
            <div className={styles.incomeFieldsetTitle}>
              {t('incomePage.fieldset.title')}
            </div>

            <div className="input-wrapper">
              <IMaskInput
                name="alimony"
                blocks={configBlocks}
                value={input.alimony}
                disabled={isLoading || isLoanLoading}
                placeholder={t('incomePage.fieldset.input.alimony')}
                mask="inputMask"
                onAccept={(
                  _value: any,
                  _mask: { _unmaskedValue: any },
                  e: any,
                ) => {
                  if (e) {
                    onInputChange({
                      // @ts-ignore
                      name: e!.target!.name as string,
                      value: _mask._unmaskedValue,
                    })
                  }
                }}
              />
            </div>

            <div className="input-wrapper">
              <IMaskInput
                name="allowance"
                blocks={configBlocks}
                value={input.allowance}
                disabled={isLoading || isLoanLoading}
                placeholder={t('incomePage.fieldset.input.allowance')}
                mask="inputMask"
                onAccept={(
                  _value: any,
                  _mask: { _unmaskedValue: any },
                  e: any,
                ) => {
                  if (e) {
                    onInputChange({
                      // @ts-ignore
                      name: e!.target!.name as string,
                      value: _mask._unmaskedValue,
                    })
                  }
                }}
              />
            </div>

            <div className="input-wrapper">
              <IMaskInput
                name="pension"
                blocks={configBlocks}
                value={input.pension}
                disabled={isLoading || isLoanLoading}
                placeholder={t('incomePage.fieldset.input.pension')}
                mask="inputMask"
                onAccept={(
                  _value: any,
                  _mask: { _unmaskedValue: any },
                  e: any,
                ) => {
                  if (e) {
                    onInputChange({
                      // @ts-ignore
                      name: e!.target!.name as string,
                      value: _mask._unmaskedValue,
                    })
                  }
                }}
              />
            </div>

            <div className="input-wrapper">
              <IMaskInput
                name="rent"
                blocks={configBlocks}
                value={input.rent}
                disabled={isLoading || isLoanLoading}
                placeholder={t('incomePage.fieldset.input.rent')}
                mask="inputMask"
                onAccept={(
                  _value: any,
                  _mask: { _unmaskedValue: any },
                  e: any,
                ) => {
                  if (e) {
                    onInputChange({
                      // @ts-ignore
                      name: e!.target!.name as string,
                      value: _mask._unmaskedValue,
                    })
                  }
                }}
              />
            </div>
          </fieldset>
        </form>

        <Button
          type={EButtonType.button}
          disabled={!salary || isLoanLoading}
          onClick={updateProfileHandler}
        >
          {t('incomePage.next')}
        </Button>
      </section>
    </>
  )
}
