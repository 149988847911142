export const MarriedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97"
      height="92"
      viewBox="0 0 97 92"
      fill="none"
    >
      <path
        d="M39.2927 80.0597L49.7204 46.6372C51.4409 41.1299 47.4964 35.4769 41.9364 35.4769H8.19142C2.63138 35.4769 -1.31311 29.8239 0.407361 24.3166L6.15624 5.89339C7.25426 2.38212 10.3945 0 13.9403 0H88.8086C94.3686 0 98.3131 5.65299 96.5926 11.1603L73.4573 85.3266C72.3592 88.8379 69.219 91.22 65.6732 91.22H47.0768C41.5167 91.22 37.5722 85.567 39.2927 80.0597Z"
        fill="#89EFD7"
      />
    </svg>
  )
}
