import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider, ModalProvider } from '@/core/context'

import App from '@/App'
import { Worker } from '@react-pdf-viewer/core'
import reportWebVitals from './reportWebVitals'

import './i18n'

import '@/assets/styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <StrictMode>
  <Suspense fallback="...loading">
    <BrowserRouter>
      <AuthProvider>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <ModalProvider>
            <App />
          </ModalProvider>
        </Worker>
      </AuthProvider>
    </BrowserRouter>
  </Suspense>,
  // </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
