/* eslint-disable @typescript-eslint/no-var-requires */

import { HTMLAttributes } from 'react'

export enum EPictureExt {
  png = '.png',
  jpeg = '.jpeg',
  jpg = '.jpg',
}
interface IPictureProps extends HTMLAttributes<HTMLDivElement> {
  img: {
    name: string
    path: string
    ext: EPictureExt
  }
}
export const Picture = ({ img, ...rest }: IPictureProps) => {
  const { name, path, ext } = img
  const avifSrc = require(`@/assets/images/${path}/${name}.avif`)
  const imgSrc = require(`@/assets/images/${path}/${name}${ext}`)

  return (
    <div {...rest}>
      <picture>
        <source srcSet={avifSrc} type="image/avif" />
        <img src={imgSrc} alt="img" />
      </picture>
    </div>
  )
}
