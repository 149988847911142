import { Icon } from '@/components/ui'

import styles from './style.module.scss'

interface IIntroProps {
  title?: string
  description?: string
}
export const Intro = ({ title, description }: IIntroProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
      <img src={`/Untitled design.svg.svg`} alt="Icon" width={120 } height={120} />

      </div> 

      {title && <div className={styles.title}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
    </div>
  )
}
