/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import { EEmplymentType, TUpdateLoanRequestPayload } from '@/core/api/types'

import { BackPage, EPictureExt, Picture, RenderTitle } from '@/components/smart'
import { Button, EButtonType, RadioButtonGroup } from '@/components/ui'

import { EQuizStep } from '@/pages'
import './style.scss'

interface IEmploymentPartnerProps {
  setStep: Dispatch<SetStateAction<EQuizStep | undefined>>
  setUpdatePayload: Dispatch<SetStateAction<TUpdateLoanRequestPayload>>
  initialData: TUpdateLoanRequestPayload
}

export const EmploymentPartner = ({
  setStep,
  setUpdatePayload,
  initialData,
}: IEmploymentPartnerProps) => {
  const { t } = useTranslation()

  const [partnerEmploymentType, setPartnerEmploymentType] = useState(
    initialData.partnerEmploymentType &&
      initialData.partnerEmploymentType !== 'N/A'
      ? initialData.partnerEmploymentType
      : ('' as EEmplymentType),
  )

  const RadioChildren = ({
    text,
    image,
  }: {
    text: string
    image: {
      name: string
      alt: string
    }
  }) => {
    return (
      <div className="radio-children">
        <div className="radio-children__text">{text}</div>

        <div className="img-container">
          <Picture
            img={{
              name: image.name,
              path: 'employment',
              ext: EPictureExt.png,
            }}
          />
        </div>
      </div>
    )
  }

  const radioList = [
    {
      value: EEmplymentType.FREELANCER,
      name: 'button-types',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('employmentParthnerTypePage.type.FREELANCER')}
          image={{ name: 'freelancer', alt: 'freelancer' }}
        />
      ),
    },
    {
      value: EEmplymentType.CONTRACT,
      name: 'button-types',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('employmentParthnerTypePage.type.CONTRACT')}
          image={{ name: 'contract', alt: 'contract' }}
        />
      ),
    },
    {
      value: EEmplymentType.BETWEEN_JOBS,
      name: 'button-types',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('employmentParthnerTypePage.type.BETWEEN_JOBS')}
          image={{ name: 'beetween_jobs', alt: 'beetween_jobs' }}
        />
      ),
    },
    {
      value: EEmplymentType.PENSION,
      name: 'button-types',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('employmentParthnerTypePage.type.PENSION')}
          image={{ name: 'pension', alt: 'pension' }}
        />
      ),
    },
  ]

  const radioGroupHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPartnerEmploymentType(event.target.value as EEmplymentType)
  }

  const updateProfileHandler = () => {
    const payload = {
      partnerEmploymentType: partnerEmploymentType,
    } as TUpdateLoanRequestPayload

    ReactGA.event({
      category: 'Button Click',
      action: 'partnerEmploymentType',
      label: 'Quiz Page',
    })
    ReactGA.send({
      fieldsObject: payload,
    })

    setUpdatePayload((prev) => ({
      ...prev,
      ...payload,
    }))

    setStep(EQuizStep.INCOME)
  }

  return (
    <>
      <BackPage
        onNavigate={() => {
          setUpdatePayload((prev) => ({
            ...prev,
            maritalStatus: initialData.maritalStatus,
            partnerEmploymentType: 'N/A',
          }))

          setStep(EQuizStep.MERITAL_STATUS)
        }}
      />
      {/* onClick={() => setStep(EQuizStep.INCOME)} */}
      <section className="employment">
        <RenderTitle titleKey={'employmentParthnerTypePage.title'} />
        <form>
          <RadioButtonGroup
            label=""
            options={radioList}
            onChange={radioGroupHandler}
            selectedValue={partnerEmploymentType}
          />
        </form>

        <Button
          type={EButtonType.button}
          disabled={!partnerEmploymentType}
          onClick={updateProfileHandler}
        >
          {t('employmentParthnerTypePage.next')}
        </Button>
      </section>
    </>
  )
}
