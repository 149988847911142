/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@/core/context'
import { EIgnoredBank, TUpdateLoanRequestPayload } from '@/core/api/types'
import { getLoanRequestApiCall, refreshTokenApiCall } from '@/core/api/calls'

import { Button, EButtonType } from '@/components/ui'
import { BackPage, EPictureExt, Picture, RenderTitle } from '@/components/smart'

import { EQuizStep } from '@/pages'

import './style.scss'
interface IBankIgnoreProps {
  setStep: Dispatch<SetStateAction<EQuizStep | undefined>>
  setUpdatePayload: Dispatch<SetStateAction<TUpdateLoanRequestPayload>>
  initialData: TUpdateLoanRequestPayload
  loanRequestUpdater: (payload: TUpdateLoanRequestPayload) => Promise<void>
}

export const BankIgnore = ({
  setStep,
  setUpdatePayload,
  initialData,
  loanRequestUpdater,
}: IBankIgnoreProps) => {
  const { user, updateAuthCredentials } = useAuth()
  const { t } = useTranslation()

  const [ignoreBankType, setIgnoreBankType] = useState(
    initialData.bankBlackList
      ? initialData.bankBlackList
      : ([] as EIgnoredBank[]),
  )
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isLoanLoading, setLoanLoading] = useState<boolean>(false)

  const initialBankList = () => {
    const defaultData = [
      {
        value: EIgnoredBank.ONEZERO,
        name: 'oneZero',
        isSelected: false,
      },
      {
        value: EIgnoredBank.BENLEUMI,
        name: 'benleumi',
        isSelected: false,
      },
      {
        value: EIgnoredBank.DISCONT,
        name: 'discont',
        isSelected: false,
      },
      {
        value: EIgnoredBank.IGUD,
        name: 'igud',
        isSelected: false,
      },
      {
        value: EIgnoredBank.LEUMI,
        name: 'leumi',
        isSelected: false,
      },
      {
        value: EIgnoredBank.MIZRACHI,
        name: 'mizrahi',
        isSelected: false,
      },
      {
        value: EIgnoredBank.POALIM,
        name: 'poalim',
        isSelected: false,
      },
      {
        value: EIgnoredBank.YAHAV,
        name: 'yahav',
        isSelected: false,
      },
    ]

    if (initialData.bankBlackList?.length) {
      return defaultData.reduce(
        (acc, cur) => {
          const curItem = { ...cur }
          console.log(`initialData ${JSON.stringify(initialData)}`)
          if (initialData && initialData.bankBlackList) {
            initialData.bankBlackList?.forEach((item) => {
              if (cur.value === item) {
                curItem.isSelected = true
              }
            })
          }
          acc.push(curItem)
          return acc
        },
        [] as {
          value: EIgnoredBank
          name: string
          isSelected: boolean
        }[],
      )
    } else return defaultData
  }

  const [bankList, setBankList] = useState(initialBankList())

  const updateProfileHandler = async () => {
    const payload = {
      ...initialData,
      bankBlackList: ignoreBankType,
    }

    try {
      setLoading(true)
      await loanRequestUpdater(payload)
      let response = await getLoanRequest()
      console.log(response)

      if (Object.prototype.hasOwnProperty.call(response, 'error')) {
        response = await getLoanRequest()
      } else {
        setUpdatePayload((prev) => ({
          ...prev,
          ...response,
        }))

        setStep(EQuizStep.WHATSAPP)
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getLoanRequest = async () => {
    try {
      if (user) {
        setLoanLoading(true)
        const response = await getLoanRequestApiCall(user)
        return response
      }
    } catch (error: any) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === 'Failed to fetch') {
          const response =
            user &&
            (await refreshTokenApiCall(
              {
                refreshToken: user.RefreshToken,
                username: user.username,
              },
              user,
            ))
          console.log('refreshToken:', response)
          updateAuthCredentials({ ...user, ...response })
        }
      }
    } finally {
      setLoanLoading(false)
    }
  }

  const selectionBankHandler = (index: number) => {
    const item = bankList[index]
    const newArr = [...bankList]

    newArr[index]['isSelected'] = !item.isSelected

    setBankList(newArr)
  }

  useEffect(() => {
    setIgnoreBankType(
      bankList.filter((item) => item.isSelected).map((item) => item.value),
    )
  }, [bankList])

  return (
    <>
      <BackPage onNavigate={() => setStep(EQuizStep.INCOME)} />
      <section className="bank-ignored">
        <RenderTitle titleKey={'bankIgnore.title'} />

        <ul>
          {bankList.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => selectionBankHandler(index)}
                className={item.isSelected ? 'active' : ''}
              >
                <Picture
                  img={{
                    name: item.name,
                    path: 'bank',
                    ext: EPictureExt.png,
                  }}
                />
              </li>
            )
          })}
        </ul>

        <Button
          type={EButtonType.button}
          disabled={isLoading || isLoanLoading}
          onClick={updateProfileHandler}
        >
          {t('bankIgnore.next')}
        </Button>
      </section>
    </>
  )
}
