/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import { useAuth } from '@/core/context'
import {
  loginApiCall,
  refreshTokenApiCall,
  verifyLoginApiCall,
} from '@/core/api/calls'
import {
  TUpdateLoanRequestPayload,
  TVerifyLoginApiCallPayload,
} from '@/core/api/types'

import { Button, EButtonType } from '@/components/ui'
import { BackPage, OTPField, Timer } from '@/components/smart'

import styles from './style.module.scss'

interface ILocation {
  input: TUpdateLoanRequestPayload
  response: {
    codeVerifier: string
    session: string
    username: string
  }
}

export const VerificationPage = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'page.verification',
  })
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as ILocation
  const { login, user, updateA4UStorageData, updateAuthCredentials } = useAuth()

  const historyDada = locationState.input
  const [loginResponse, setLoginResponse] = useState(locationState.response)

  const [isLoading, setLoading] = useState<boolean>(false)
  const [currentCode, setCurrentCode] = useState<string[]>()
  const [codeError, setCodeError] = useState<boolean>(false)
  const [timerError, setTimerError] = useState<boolean>(false)
  const [isCodeFilled, setCodeFilled] = useState(false)

  const [_, forceUpdate] = useReducer((x) => x + 1, 0)

  const resetTokenHandler = async (user: any) => {
    try {
      const response =
        user &&
        (await refreshTokenApiCall(
          {
            refreshToken: user.RefreshToken,
            username: user.username,
          },
          user,
        ))

      updateAuthCredentials({ ...user, ...response })
    } catch (error) {
      console.log('error: ', error)
    }
  }

  const handleCurrentCodeChange = useCallback((value: string[]) => {
    setCurrentCode(value)
    setCodeError(false)
  }, [])

  const handleTimerReset = useCallback(() => {
    forceUpdate()
  }, [_])

  const stopTimerHandler = useCallback(() => {
    setTimerError(true)
  }, [])

  useEffect(() => {
    if (!loginResponse || !historyDada) {
      navigate('/')
    }
  }, [loginResponse, historyDada])

  useEffect(() => {
    setCodeFilled(!!currentCode && currentCode.every((item) => item !== ''))
  }, [currentCode])

  useEffect(() => {
    if (isCodeFilled && (!timerError || !codeError)) {
      verificationHandler((currentCode as string[]).join(''))
    }
  }, [isCodeFilled])

  const verificationHandler = async (code: string) => {
    const vPayload = { code, ...loginResponse } as TVerifyLoginApiCallPayload

    try {
      setLoading(true)
      ReactGA.event({
        category: 'Button Click',
        action: 'Verification handler',
        label: 'Verification Page',
      })
      const response = await verifyLoginApiCall(vPayload)

      if (response) {
        login({ ...response, username: vPayload.username })

        updateA4UStorageData(historyDada && { ...historyDada })

        navigate('/quiz', {
          state: {
            ...historyDada,
          },
        })
      }
    } catch (error: any) {
      if (error !== 'CODE_EXPIRED') {
        user && resetTokenHandler(user)
      }
      setCodeError(true)
    } finally {
      setLoading(false)
    }
  }

  const resetVerificationCode = async (phone: string) => {
    try {
      const response = (await loginApiCall(phone)) as unknown as {
        codeVerifier: string
        session: string
        username: string
      }
      setLoginResponse({ ...response })
      setCodeError(false)
      setTimerError(false)
      handleTimerReset()
      handleCurrentCodeChange(new Array(6).fill(''))
    } catch (error: any) {
      if (error.message === 'Failed to fetch') {
        navigate('/')
      }
    }
  }

  return (
    <>
      <BackPage onNavigate={() => navigate('/auth')} />
      <section className={styles.verificationPage}>
        <div className="container">
          <div>
            <div className="title">{t('title')}</div>
            <div className="description">{t('description')}</div>
          </div>

          <div className={styles.otpContainer}>
            <p>
              {t('otp.container.text')}
              <br />
              <span>
                XXX-XXX{' '}
                {loginResponse?.username.slice(
                  loginResponse?.username.length - 4,
                )}
              </span>
            </p>

            <div className={styles.otpBlock}>
              <OTPField
                onReset={handleTimerReset}
                onChange={handleCurrentCodeChange}
                error={timerError || codeError}
                isDisabled={isLoading}
              />

              <div className={styles.otpBlockController}>
                <div className={styles.otpBlockTimer}>
                  {t('otp.block.controller.timer')}:&nbsp;
                  <Timer
                    onReset={handleTimerReset}
                    onEndTimer={stopTimerHandler}
                  />
                </div>
                <Button
                  isLinkView
                  disabled={!timerError}
                  onClick={() =>
                    resetVerificationCode(loginResponse?.username as string)
                  }
                >
                  {t('otp.block.controller.newCode')}
                </Button>
              </div>
              <div className={styles.otpBlockError}>
                {timerError
                  ? t('otp.block.controller.error.expired')
                  : codeError
                  ? t('otp.block.controller.error.invalidCode')
                  : ''}
              </div>
            </div>
          </div>

          <Button
            type={EButtonType.button}
            isLoading={isLoading}
            disabled={!isCodeFilled || timerError}
            onClick={() =>
              verificationHandler((currentCode && currentCode.join('')) || '')
            }
          >
            {t('btn')}
          </Button>
        </div>
      </section>
    </>
  )
}
