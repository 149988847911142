/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMask, IMaskInput } from 'react-imask'
import ReactGA from 'react-ga'

import { TUpdateLoanRequestPayload } from '@/core/api/types'

import { Button, EButtonType } from '@/components/ui'
import { BackPage, RangeComponent, RenderTitle } from '@/components/smart'

import { EQuizStep } from '@/pages'
import './style.scss'

interface ILoanProps {
  setStep: Dispatch<SetStateAction<EQuizStep | undefined>>
  setUpdatePayload: Dispatch<SetStateAction<TUpdateLoanRequestPayload>>
  initialData: Pick<
    TUpdateLoanRequestPayload,
    'requestedLoan' | 'monthlyReturn'
  >
}

const configBlocks = {
  inputMask: {
    mask: '₪{ }VALUE',
    blocks: {
      VALUE: {
        mask: IMask.MaskedNumber,
        min: 0,
        max: 500000,
        thousandsSeparator: ',',
      },
    },
  },
}

export const Loan = ({
  setStep,
  setUpdatePayload,
  initialData,
}: ILoanProps) => {
  const { t } = useTranslation()

  const [loanRequestValue, setLoanRequestValue] = useState<number>(
    initialData.requestedLoan && initialData.requestedLoan !== 'N/A'
      ? parseInt(initialData.requestedLoan, 10)
      : 0,
  )
  const [loanReturnValue, setLoanReturnValue] = useState<number>(
    initialData.monthlyReturn && initialData.monthlyReturn !== 'N/A'
      ? parseInt(initialData.monthlyReturn, 10)
      : 0,
  )

  const [isLoanRequestDisabled, setLoadRequestDisabled] =
    useState<boolean>(false)

  const [input, setInput] = useState({
    loanRequestInputValue: '',
  })

  const onInputChange = ({ name, value }: { name: string; value: string }) => {
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const loanRequestRangeHandler = (value: number) => {
    setLoanRequestValue(value)
  }
  const loanReturnRangeHandler = (value: number) => {
    setLoanReturnValue(value)
  }

  const updateProfileHandler = () => {
    const payload = {
      monthlyReturn: loanReturnValue.toString(),
      requestedLoan: isLoanRequestDisabled
        ? input.loanRequestInputValue
        : loanRequestValue.toString(),
    } as TUpdateLoanRequestPayload
    ReactGA.event({
      category: 'Button Click',
      action: 'requestedLoan & monthlyReturn',
      label: 'Quiz Page',
    })
    ReactGA.send({
      fieldsObject: payload,
    })
    setUpdatePayload((prev) => ({
      ...prev,
      ...payload,
    }))

    setStep(EQuizStep.REAL_ESTATE)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const calculateReturnValue = () => {
    if (!Number.isNaN(parseInt(input.loanRequestInputValue))) {
      if (parseInt(input.loanRequestInputValue) < 1000) return 0
      return loanReturnValue >
        (parseInt(input.loanRequestInputValue) * 10) / 100
        ? new Intl.NumberFormat('he').format(
            (parseInt(input.loanRequestInputValue) * 10) / 100,
          )
        : new Intl.NumberFormat('he').format(loanReturnValue)
    } else {
      if (loanRequestValue === 0) {
        return 0
      } else {
        return loanReturnValue > (loanRequestValue * 10) / 100
          ? new Intl.NumberFormat('he').format((loanRequestValue * 10) / 100)
          : new Intl.NumberFormat('he').format(loanReturnValue)
      }
    }
  }

  const generateMaxRangeValue = () => {
    if (!Number.isNaN(parseInt(input.loanRequestInputValue))) {
      return parseInt(input.loanRequestInputValue, 10) <= 25000
        ? parseInt(input.loanRequestInputValue, 10)
        : 25000
    } else {
      if (loanRequestValue === 0) {
        return 0
      } else {
        return loanRequestValue <= 25000 ? loanRequestValue : 25000
      }
    }
  }

  useEffect(() => {
    if (!Number.isNaN(parseInt(input.loanRequestInputValue))) {
      setLoadRequestDisabled(true)
    } else {
      setLoadRequestDisabled(false)
    }
  }, [input.loanRequestInputValue])

  return (
    <>
      <BackPage onNavigate={() => setStep(EQuizStep.GOAL)} />
      <section className="loan">
        <RenderTitle
          titleKey={'loanRequest.title'}
          // descriptionKey={'loanRequest.description'}
        />
        <ul className="loan-list">
          <li className="loan-list-item">
            <div className="loan-list-item-info">
              <div className="loan-list-item-info__label">
                {t('loanRequest.request')}
              </div>
              <div className="loan-list-item-info__value">
                {new Intl.NumberFormat('he').format(loanRequestValue)} ₪
              </div>
            </div>

            <div className="loan-list-item__range">
              <RangeComponent
                initValue={loanRequestValue}
                min={0}
                max={500000}
                onChange={loanRequestRangeHandler}
                color={'#3ae'}
                step={500}
                isDisabled={isLoanRequestDisabled}
              />
            </div>
          </li>
          <li className="loan-list-item loan-list-item--input">
            <div className="input-wrapper">
              <IMaskInput
                name="loanRequestInputValue"
                blocks={configBlocks}
                value={input.loanRequestInputValue}
                placeholder={'או סכום מדויק שאני רוצה בש"ח'}
                mask="inputMask"
                onAccept={(_value, _mask, e) => {
                  if (e) {
                    onInputChange({
                      name: (e!.target as any).name as string,
                      value: _mask._unmaskedValue,
                    })
                  }
                }}
              />
            </div>
          </li>
          <li className="loan-list-item">
            <div className="loan-list-item-info">
              <div className="loan-list-item-info__label">
                {t('loanRequest.return')}
              </div>
              <div className="loan-list-item-info__value loan-list-item-info__value--return">
                {/* {calculateReturnValue()} ₪ */}
                {new Intl.NumberFormat('he').format(loanReturnValue)} ₪
              </div>
            </div>

            <div className="loan-list-item__range">
              <RangeComponent
                initValue={loanReturnValue}
                min={0}
                max={
                  generateMaxRangeValue()
                  // parseInt(input.loanRequestInputValue, 10) < 25000 ? parseInt(input.loanRequestInputValue, 10) : 25000
                  // input.loanRequestInputValue
                  //   ? (parseInt(input.loanRequestInputValue, 10) * 10) / 100
                  //   : (loanRequestValue * 10) / 100
                }
                onChange={loanReturnRangeHandler}
                color={'#6A4AE3'}
                step={100}
              />
            </div>
          </li>
        </ul>

        <Button
          type={EButtonType.button}
          disabled={loanRequestValue === 0 || loanReturnValue === 0}
          onClick={updateProfileHandler}
        >
          {t('goalPage.action')}
        </Button>
      </section>
    </>
  )
}
