/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IMaskInput, IMask } from 'react-imask'
import ReactGA from 'react-ga'

import { useAuth, useModal } from '@/core/context'
import {
  getLoanRequestApiCall,
  refreshTokenApiCall,
  saveUserCardApiCall,
} from '@/core/api/calls'

import { Button, EButtonType, Icon } from '@/components/ui'

import './payment.scss'

// Core viewer
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import customZoomPlugin from '@/components/pdf-viewer/customZoomPlugin'

import { useNavigate } from 'react-router-dom'
import { RenderTitle } from '@/components/smart'

const configBlocks = {
  cardNumber: {
    mask: [
      {
        mask: '0000 000000 00000',
        regex: '^3[47]\\d{0,13}',
        cardtype: 'amex',
      },
      {
        mask: '0000 000000 0000',
        regex: '^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}',
        cardtype: 'diners',
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}',
        cardtype: 'discover',
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
        cardtype: 'mastercard',
      },
      {
        mask: '0000 000000 00000',
        regex: '^(?:2131|1800)\\d{0,11}',
        cardtype: 'jcb',
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^(?:35\\d{0,2})\\d{0,12}',
        cardtype: 'jcb',
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}',
        cardtype: 'maestro',
      },
      {
        mask: '0000-0000-0000-0000',
        regex: '^220[0-4]\\d{0,12}',
        cardtype: 'mir',
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^4\\d{0,15}',
        cardtype: 'visa',
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^62\\d{0,14}',
        cardtype: 'unionpay',
      },
      {
        mask: '0000 0000 0000 0000',
        cardtype: 'Unknown',
      },
    ],
    dispatch: function (
      appended: any,
      dynamicMasked: { value: any; compiledMasks: string | any[] },
    ) {
      const number = (dynamicMasked.value + appended).replace(/\D/g, '')

      for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
        const re = new RegExp(dynamicMasked.compiledMasks[i].regex)
        if (number.match(re) != null) {
          return dynamicMasked.compiledMasks[i]
        }
      }
    },
  },
  expirationDate: {
    mask: 'MM{/}YY',
    blocks: {
      YY: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 99,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
    },
  },
  securityCode: {
    mask: '0000',
  },
}

const AgreementComponent = ({
  fileUrl,
  link,
  setAgree,
}: {
  fileUrl: string
  link: string
  setAgree: Dispatch<SetStateAction<boolean>>
}) => {
  const customZoomPluginInstance = customZoomPlugin()
  const { zoomTo } = customZoomPluginInstance

  const { setModal } = useModal()

  const [isConfirm, setConfirmStatus] = useState<boolean>(false)

  return (
    <div className="agreement">
      <div className="agreement-status">
        {isConfirm && <Icon icon={'check'} className="icon" />}
      </div>
      <div>
        אני מסכים{' '}
        <span
          onClick={() => {
            ReactGA.modalview('agreement modal')

            setModal(
              <div className="agreement-modal">
                <div className="agreement-modal__header">
                  <button onClick={() => zoomTo(SpecialZoomLevel.ActualSize)}>
                    Actual size
                  </button>
                  <button onClick={() => zoomTo(SpecialZoomLevel.PageWidth)}>
                    Page width
                  </button>
                  <button onClick={() => zoomTo(0.5)}>50%</button>
                  <button onClick={() => zoomTo(1.5)}>150%</button>
                </div>
                <div className="agreement-modal__content">
                  <div
                    style={{
                      padding: '20px 0',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={{ overflow: 'hidden' }}>
                      <Viewer
                        fileUrl={fileUrl}
                        plugins={[customZoomPluginInstance]}
                      />
                    </div>

                    <Button
                      disabled={isConfirm}
                      onClick={() => {
                        ReactGA.event({
                          category: 'Button Click',
                          action: 'agreement confirm',
                          label: 'Payment Page',
                        })
                        setModal(null)
                        setConfirmStatus(true)
                        setAgree(true)
                      }}
                    >
                      מסכים
                    </Button>
                  </div>
                </div>
              </div>,
            )
          }}
        >
          {link}
        </span>
      </div>
    </div>
  )
}

export const Payment = () => {
  const { user, A4UStorageData, updateAuthCredentials, updateA4UStorageData } =
    useAuth()
  const navigate = useNavigate()
  const [isLoanLoading, setLoanLoading] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [cardtype, setCardType] = useState<string | null>(null)
  const [commition, setCommition] = useState<string | number>(0)

  const buttonClickHandler = async () => {
    try {
      setLoading(true)
      const response = await saveUserCardApiCall(input, user)
      console.log('saveUserCardApiCall: ', response)
      if (response?.message) {
        navigate('finish')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const infoList = ['אין דמי פתיחת תיק', 'אין מקדמות', 'אין תשלום מראש']
  const [isAgreePaymentRules, setAgreePaymentRules] = useState(false)
  const [isAgreePaymentUse, setAgreePaymentUse] = useState(false)
  const [input, setInput] = useState({
    cardNumber: '5432 4123 1231 2312',
    cvv: '123',
    cardDate: '11/24',
  })

  const onInputChange = (event: InputEvent) => {
    const { name, value } = event.target as any

    console.log({ name, value })
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const getLoanRequest = async () => {
    try {
      if (user) {
        setLoanLoading(true)
        const response = await getLoanRequestApiCall(user)
        if (response) {
          updateA4UStorageData(response)
        }
      }
    } catch (error: any) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === 'Failed to fetch') {
          const response =
            user &&
            (await refreshTokenApiCall(
              {
                refreshToken: user.RefreshToken,
                username: user.username,
              },
              user,
            ))
          console.log('refreshToken:', response)
          updateAuthCredentials({ ...user, ...response })
        }
      }
    } finally {
      setLoanLoading(false)
      console.log('A4UStorageData: ', A4UStorageData)
      if (A4UStorageData) {
        const { commition } = A4UStorageData
        commition && setCommition(commition)
      }

      // if (Object.prototype.hasOwnProperty.call(localStorage, 'A4U')) {
      //   if (JSON.parse(localStorage?.A4U)) {
      //     const { commition } = JSON.parse(localStorage?.A4U)
      //     console.log('commition: ', commition)
      //     setCommition(commition)
      //   }
      // }
    }
  }

  useEffect(() => {
    getLoanRequest()
    // if (Object.prototype.hasOwnProperty.call(localStorage, 'A4U')) {
    //   if (JSON.parse(localStorage?.A4U)) {
    //     const { commition } = JSON.parse(localStorage?.A4U)
    //     console.log('commition: ', commition)
    //     setCommition(commition)
    //   }
    // }
  }, [])
  return (
    <section className="payment-page">
      <div className="container payment-page-container">
        <RenderTitle titleKey={'סיימת, מכאן אנחנו מתחילים לעבוד'} />

        <form className="payment-form">
          <div className="payment-page-info">
            <div className="payment-page-info__title">חשוב לדעת שאצלנו:</div>

            <ul className="payment-page-info-list">
              {infoList.map((item, index) => {
                return (
                  <li className="payment-page-info-list__item" key={index}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="19"
                      viewBox="0 0 24 19"
                      fill="none"
                    >
                      <path
                        d="M0.22453 9.39365C-0.983571 8.33716 3.01403 8.50844 4.35575 9.39365C5.69488 10.2773 7.59735 13.4567 7.59735 13.4567C7.59735 13.4567 15.5345 2.77458 21.5614 0.158C23.0383 -0.483259 24.9207 0.986545 23.4901 1.72353C16.9294 5.10302 11.5959 14.1559 10.0408 17.2376C9.20794 18.8886 6.34125 19.7863 5.61866 18.0854C5.17596 17.043 2.65825 11.5223 0.22453 9.39365Z"
                        fill="currentColor"
                      />
                    </svg>{' '}
                    {item}
                  </li>
                )
              })}
            </ul>

            <div className="payment-page-info__description">
              <span>
                והעמלה בסך {isLoanLoading ? '... ' : commition} {'ש״ח'} הינה
              </span>
              <br />
              על בסיס הצלחה בלבד ורק לאחר שהכסף מועבר לחשבון.
            </div>
          </div>

          {/* <div className="payment-page-form-desc">
            כדי להתחיל לעבוד אנחנו לוקחים מספר כרטיס אשראי לביטחון בלבד ולא
            נוגעים בו
          </div>

          <fieldset className="payment-form__container">
            <div className="input-wrapper">
              <IMaskInput
                blocks={configBlocks}
                mask="cardNumber"
                disabled={isLoading}
                onAccept={(_value, mask, e) => {
                  setCardType(
                    // @ts-ignore
                    mask.masked._blocks[0].currentMask.cardtype !== 'Unknown'
                      ? // @ts-ignore
                        mask.masked._blocks[0].currentMask.cardtype
                      : null,
                  )

                  onInputChange(e!)
                }}
                placeholder="מספר כרטיס אשראי שלך לביטחון בלבד"
                name="cardNumber"
              />
              {cardtype && (
                <Icon icon={cardtype} viewBox="0 0 780 500" size="70px" />
              )}
            </div>

            <div className="input-wrapper">
              <IMaskInput
                blocks={configBlocks}
                mask="expirationDate"
                disabled={isLoading}
                onAccept={(_value, _mask, e) => {
                  onInputChange(e!)
                }}
                placeholder="תאריך"
                name="cardDate"
              />
            </div>

            <div className="input-wrapper">
              <IMaskInput
                blocks={configBlocks}
                mask="securityCode"
                disabled={isLoading}
                onAccept={(_value, _mask, e) => {
                  onInputChange(e!)
                }}
                placeholder="CVV"
                name="cvv"
              />
            </div>
          </fieldset> */}

          <div className="payment-form__agreement-container">
            <AgreementComponent
              fileUrl={'/paymentTerms.pdf'}
              link={'לתנאי התשלום'}
              setAgree={setAgreePaymentRules}
            />
            <AgreementComponent
              fileUrl={'/userTerms.pdf'}
              link={'לתנאי השימוש'}
              setAgree={setAgreePaymentUse}
            />
          </div>
        </form>

        <Button
          type={EButtonType.button}
          disabled={
            // !input.cardNumber.length ||
            // !input.cardDate.length ||
            // !input.cvv.length ||
            isLoanLoading || !isAgreePaymentRules || !isAgreePaymentUse
          }
          onClick={buttonClickHandler}
        >
          סיימתי
        </Button>
      </div>
    </section>
  )
}
