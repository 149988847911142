/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@/core/context'
import { EEndpoint, apiRequest } from '@/core/api'

import { Button, Input } from '@/components/ui'
import { EUserApiPath } from '@/core/api/types'

export const Remove = () => {
  const navigate = useNavigate()
  const { user } = useAuth()

  const [input, setInput] = useState({
    username: '',
  })

  const onInputChange = (event: any) => {
    console.log(event.target.value)
    const { value } = event.target
    setInput((prev) => ({
      ...prev,
      username: value,
    }))
  }

  const removeUserHandler = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await apiRequest({
        apiEndpoint: EEndpoint.user,
        path: EUserApiPath.profile,
        options: {
          method: 'DELETE',
          headers: {
            Authorization: `${user?.TokenType} ${user?.AccessToken}`,
          },
        },
      })

      console.log(response)
      if (response?.message === 'DONE') {
        localStorage.clear()
        navigate('/')
      }
    } catch (error) {
      throw error
    }
  }
  return (
    <div className="container">
      <Input
        type="text"
        readOnly={false}
        value={input.username}
        onInput={onInputChange}
      />
      <Button onClick={removeUserHandler}>Remove user</Button>
    </div>
  )
}
