/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import ReactGA from 'react-ga'

import { Button, Icon } from '@/components/ui'

import './style.scss'
import { EQuizStep } from '@/pages'
import { BackPage } from '@/components/smart'
import { Trans, useTranslation } from 'react-i18next'

interface IWhatsAppProps {
  setStep: Dispatch<SetStateAction<EQuizStep | undefined>>
  isLoadingUpdate: boolean
}

export const WhatsApp = ({ setStep, isLoadingUpdate }: IWhatsAppProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'whatsappPage' })

  // @ts-ignore
  const [isCongrats, setCongrats] = useOutletContext()

  useEffect(() => {
    console.log({
      isCongrats,
      setCongrats,
    })
    setCongrats(true)
  }, [])

  const handleWhatsAppClick = () => {
    ReactGA.event({
      category: 'Button Click',
      action: 'redirect to whatsapp',
      label: 'Quiz Page',
    })

    const whatsappUrl = 'https://wa.me/message/GEGO5WDVYV3QP1'

    window.open(whatsappUrl, '_blank')
  }
  return (
    <>
      <BackPage
        onNavigate={() => {
          setCongrats(false)
          setStep(EQuizStep.INCOME)
        }}
        // hasText={false}
      />
      <div className="congrats-page">
        <div>
          <Icon
            icon={'congrats'}
            viewBox="0 0 243 240"
            className="icon-congrats"
          />

          <div className="title">הגעת לשלב האחרון!</div>
          <div className="description">
            <Trans
              components={{
                p: <p />,
              }}
            >
              {t('description')}
            </Trans>
          </div>
        </div>

        <Button onClick={handleWhatsAppClick} disabled={isLoadingUpdate}>
          <span>לעבור ל</span>
          <Icon
            icon={'whatsapp'}
            viewBox="0 0 31 30"
            size="30px"
            className="icon"
          />
        </Button>
        <div>
          לא קיבלת ווטסאפ? כדאי לבדוק בתיבת הודעות סמס בטלפון, שלחנו לך גם לשם
        </div>
      </div>
    </>
  )
}
