import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import { EMaritalStatus, TUpdateLoanRequestPayload } from '@/core/api/types'

import { Button, EButtonType, RadioButtonGroup } from '@/components/ui'
import { BackPage, RenderTitle } from '@/components/smart'

import { EQuizStep } from '@/pages'
import { DivorcedIcon, MarriedIcon, SingleIcon, WidowedIcon } from './svg'

import './style.scss'

interface IMaritalProps {
  setStep: Dispatch<SetStateAction<EQuizStep | undefined>>
  setUpdatePayload: Dispatch<SetStateAction<TUpdateLoanRequestPayload>>
  initialData: TUpdateLoanRequestPayload
}

export const Marital = ({
  setStep,
  setUpdatePayload,
  initialData,
}: IMaritalProps) => {
  const { t } = useTranslation()

  const [maritalStatus, setMaritalStatus] = useState(
    initialData.maritalStatus &&
      initialData.maritalStatus !== 'N/A' &&
      initialData.maritalStatus !== ''
      ? initialData.maritalStatus
      : ('' as EMaritalStatus),
  )

  const RadioChildren = ({
    text,
    image,
  }: {
    text: string
    image: JSX.Element
  }) => {
    return (
      <div className="radio-children">
        <div className="radio-children__text">{text}</div>
        <div className="img-container">{image}</div>
      </div>
    )
  }

  const MSradioList = [
    {
      value: EMaritalStatus.SINGLE,
      name: 'maritalStatus',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('maritalStatusPage.type.single')}
          image={<SingleIcon />}
        />
      ),
    },
    {
      value: EMaritalStatus.MARRIED,
      name: 'maritalStatus',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('maritalStatusPage.type.married')}
          image={<MarriedIcon />}
        />
      ),
    },
    {
      value: EMaritalStatus.DIVORCED,
      name: 'maritalStatus',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('maritalStatusPage.type.divorced')}
          image={<DivorcedIcon />}
        />
      ),
    },
    {
      value: EMaritalStatus.WIDOWED,
      name: 'maritalStatus',
      label: '',
      childrenComponent: (
        <RadioChildren
          text={t('maritalStatusPage.type.widowed')}
          image={<WidowedIcon />}
        />
      ),
    },
  ]

  const maritalStatusRGHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setMaritalStatus(event.target.value as EMaritalStatus)
  }

  const updateProfileHandler = () => {
    const payload = {
      maritalStatus: maritalStatus,
    } as TUpdateLoanRequestPayload

    if (
      initialData.partnerEmploymentType &&
      initialData.partnerEmploymentType !== 'N/A'
    ) {
      if (maritalStatus !== EMaritalStatus.MARRIED) {
        payload.partnerEmploymentType = 'N/A'
      }
    }

    ReactGA.event({
      category: 'Button Click',
      action: 'maritalStatus',
      label: 'Quiz Page',
    })
    ReactGA.send({
      fieldsObject: payload,
    })

    setUpdatePayload((prev) => ({
      ...prev,
      ...payload,
    }))

    maritalStatus !== EMaritalStatus.MARRIED
      ? setStep(EQuizStep.INCOME)
      : setStep(EQuizStep.MERITAL_STATUS_MARIED)
  }

  return (
    <>
      <BackPage onNavigate={() => setStep(EQuizStep.REAL_ESTATE)} />
      {/* onClick={() =>
              maritalStatus !== EMaritalStatus.MARRIED
                ? setStep(EQuizStep.INCOME)
                : setStep(EQuizStep.MERITAL_STATUS_MARIED)
            } */}
      <section className={'marital'}>
        <RenderTitle titleKey={'maritalStatusPage.title'} />
        <form>
          <RadioButtonGroup
            label=""
            options={MSradioList}
            onChange={maritalStatusRGHandler}
            selectedValue={maritalStatus}
          />
        </form>

        <Button
          type={EButtonType.button}
          disabled={!maritalStatus}
          onClick={updateProfileHandler}
        >
          {t('maritalStatusPage.next')}
        </Button>
      </section>
    </>
  )
}
