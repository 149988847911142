/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createContext, useContext, useMemo } from 'react'
import { useLocalStorage } from '../hooks'
import {
  TLoginApiCallResponse,
  TUpdateLoanRequestPayload,
  TVerifyLoginApiCallResponse,
} from '../api/types'

// @ts-ignore
const AuthContext = createContext<{
  user: TVerifyLoginApiCallResponse | null
  A4UStorageData: TUpdateLoanRequestPayload | null

  login: (data: TVerifyLoginApiCallResponse) => Promise<void>
  logout: () => void

  updateAuthCredentials: (data: TLoginApiCallResponse) => void
  updateA4UStorageData: (data: TUpdateLoanRequestPayload) => void
}>()

export const AuthProvider = ({ children }: any) => {
  // @ts-ignore
  const [user, setUser]: [
    TVerifyLoginApiCallResponse | null,
    (data: TVerifyLoginApiCallResponse | null) => void,
  ] = useLocalStorage('user', null)

  // @ts-ignore
  const [A4UStorageData, setA4UStorageData]: [
    TUpdateLoanRequestPayload | null,
    (data: TUpdateLoanRequestPayload | null) => void,
  ] = useLocalStorage('A4U', null)

  const login = async (data: TVerifyLoginApiCallResponse) => {
    setUser(data)
  }

  const logout = () => {
    setUser(null)
  }

  const updateA4UStorageData = (data: TUpdateLoanRequestPayload) => {
    setA4UStorageData(data)
  }
  const updateAuthCredentials = (data: TLoginApiCallResponse) => {
    // @ts-expect-error  old interface - not in use file deprected
    setUser(data)
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      updateAuthCredentials,

      A4UStorageData,
      updateA4UStorageData,
    }),
    [user, A4UStorageData],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
