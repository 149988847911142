/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import parsePhoneNumberFromString, { ParseError } from 'libphonenumber-js'
import ReactGA from 'react-ga'

import { loginApiCall } from '@/core/api/calls'
import { useAuth } from '@/core/context'

import { BackPage, Picture, EPictureExt } from '@/components/smart'
import { Button, EButtonType, Input } from '@/components/ui'

import styles from './style.module.scss'

interface ILocation {
  params: {
    agentId?: string
    parentAgentId?: string
    topAgentID?: string
  }
}

export const AuthPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'page.auth' })
  const navigate = useNavigate()
  const { user, A4UStorageData } = useAuth()

  const location = useLocation()
  const locationState = location.state as ILocation

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isValidIdentifier, setIsValidIdentifier] = useState(false)
  const [input, setInput] = useState({
    firstName: '',
    lastName: '',
    identifier: user?.username ? user.username : '',
  })

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  useEffect(() => {
    console.log(A4UStorageData)
  }, [A4UStorageData])

  useEffect(() => {
    const isPhoneNumberValid = isNumberValid(input.identifier)

    if (input.identifier.length === 0) setIsValidIdentifier(true)
    else if (isPhoneNumberValid && input.identifier.length > 0)
      setIsValidIdentifier(true)
    else setIsValidIdentifier(false)
  }, [input.identifier])

  const isNumberValid = (number: string) => {
    try {
      const phoneNumberObject = parsePhoneNumberFromString(
        number,
        process.env.NODE_ENV === 'development' ? 'UA' : 'IL',
      )
      return phoneNumberObject && phoneNumberObject.isValid()
    } catch (error) {
      if (error instanceof ParseError) {
        return false
      } else {
        return false
      }
    }
  }

  const loginHandler = async (phone: string) => {
    try {
      setLoading(true)
      ReactGA.event({
        category: 'Button Click',
        action: 'Login handler',
        label: 'Auth Page',
      })
      const response = (await loginApiCall(phone)) as unknown as {
        codeVerifier: string
        session: string
        username: string
      }
      console.log('response: ', response)
      navigate('/verification', {
        state: {
          response,
          input: {
            firstName: input.firstName,
            lastName: input.lastName,
            ...locationState.params,
            ...A4UStorageData,
          },
        },
      })
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <BackPage onNavigate={() => navigate('/')} />
      <section className={styles.authPage}>
        <div className="container">
          <div className="title">{t('title')}</div>

          <div className={styles.sign}>
            <div className={styles.signRow}>
              <Picture
                className={styles.pictureContainer}
                img={{
                  path: 'authPage',
                  name: 'img',
                  ext: EPictureExt.png,
                }}
              />

              <form className={styles.signForm}>
                <fieldset>
                  <Input
                    className={styles.signInput}
                    type="text"
                    name="firstName"
                    placeholder={t('form.firstName')}
                    value={input.firstName}
                    onInput={onInputChange}
                    disabled={isLoading}
                  />
                  <Input
                    className={styles.signInput}
                    type="text"
                    name="lastName"
                    placeholder={t('form.lastName')}
                    value={input.lastName}
                    onInput={onInputChange}
                    disabled={isLoading}
                  />
                  <Input
                    className={styles.signInput}
                    type={
                      process.env.NODE_ENV === 'development' ? 'text' : 'number'
                    }
                    name="identifier"
                    placeholder={t('form.phonePlaceholder')}
                    value={input.identifier}
                    onInput={onInputChange}
                    disabled={isLoading}
                    error={
                      !isValidIdentifier ? t('form.identifierErrorMessage') : ''
                    }
                  />
                </fieldset>
              </form>
            </div>

            <div className={styles.signEula}>
              <Trans
                components={{
                  a: <Link to="/" />,
                }}
              >
                {t('eula')}
              </Trans>
            </div>
          </div>

          <Button
            type={EButtonType.button}
            disabled={
              input.firstName.length === 0 ||
              input.lastName.length === 0 ||
              input.identifier.length === 0 ||
              !isValidIdentifier
            }
            onClick={() => loginHandler(input.identifier)}
          >
            {t('btn')}
          </Button>
        </div>
      </section>
    </>
  )
}
