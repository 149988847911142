/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@/core/context'
import { isEmptyObject } from '@/core/utils'
import { refreshTokenApiCall, updateLoanRequestApiCall } from '@/core/api/calls'
import { EMaritalStatus, TUpdateLoanRequestPayload } from '@/core/api/types'

import { Employment } from '@/components/sections/_Employment'
import { Goal } from '@/components/sections/_Goal'
import { Loan } from '@/components/sections/_Loan'
import { RealEstate } from '@/components/sections/_RealEstate'
import { Marital } from '@/components/sections/_Marital'
import { EmploymentPartner } from '@/components/sections/_EmploymentPartner'
import { Income } from '@/components/sections/_Income'
import { BankIgnore } from '@/components/sections/_BankIgnore'

import './style.scss'
import { EQuizStep } from './types'
import { WhatsApp } from '@/components/sections/WhatsApp'

const toTopHandler = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export const QuizPage = () => {
  const { user, A4UStorageData, updateAuthCredentials, updateA4UStorageData } =
    useAuth()
  const navigate = useNavigate()

  const [step, setStep] = useState<EQuizStep | undefined>(undefined)
  const [isLoadingUpdate, setLoadingUpdate] = useState<boolean>(false)
  const [loanRequest, setLoanRequest] = useState<TUpdateLoanRequestPayload>({})
  const [updatePayload, setUpdatePayload] = useState<TUpdateLoanRequestPayload>(
    {},
  )

  const getInitialState = (loanRequest: TUpdateLoanRequestPayload) => {
    if (
      !loanRequest.employmentType ||
      loanRequest.employmentType === 'N/A' ||
      loanRequest.employmentType === ''
    )
      setStep(EQuizStep.EMPLOYMENT)
    else if (!loanRequest.reason || loanRequest.reason === 'N/A')
      setStep(EQuizStep.GOAL)
    else if (
      !loanRequest.requestedLoan ||
      loanRequest.requestedLoan === '0' ||
      loanRequest.requestedLoan === 'N/A' ||
      !loanRequest.monthlyReturn ||
      loanRequest.monthlyReturn === '0' ||
      loanRequest.monthlyReturn === 'N/A'
    )
      setStep(EQuizStep.LOAN)
    else if (
      !loanRequest.propertyOwner ||
      loanRequest.propertyOwner === 'N/A' ||
      loanRequest.propertyOwner === '' ||
      !loanRequest.rentPayment ||
      loanRequest.rentPayment === 'N/A' ||
      loanRequest.rentPayment === ''
    )
      setStep(EQuizStep.REAL_ESTATE)
    else if (
      !loanRequest.maritalStatus ||
      loanRequest.maritalStatus === 'N/A' ||
      loanRequest.maritalStatus === ''
    )
      setStep(EQuizStep.MERITAL_STATUS)
    else if (
      (!loanRequest.partnerEmploymentType ||
        loanRequest.partnerEmploymentType === 'N/A' ||
        loanRequest.partnerEmploymentType === '') &&
      loanRequest.maritalStatus === EMaritalStatus.MARRIED
    )
      setStep(EQuizStep.MERITAL_STATUS_MARIED)
    else if (
      !loanRequest.salary ||
      loanRequest.salary === '0' ||
      loanRequest.salary === 'N/A'
    )
      setStep(EQuizStep.INCOME)
    else if (!loanRequest.bankBlackList) setStep(EQuizStep.BANK_IGNORE)
    else setStep(EQuizStep.WHATSAPP)
  }

  const loanRequestUpdater = async (payload: TUpdateLoanRequestPayload) => {
    try {
      setLoadingUpdate(true)
      const response = user && (await updateLoanRequestApiCall(payload, user))
      if (response) {
        console.log('loanRequestUpdater -> response: ', response)
      }
    } catch (error: any) {
      if (error.message === 'Failed to fetch') {
        const response =
          user &&
          (await refreshTokenApiCall(
            {
              refreshToken: user.RefreshToken,
              username: user.username,
            },
            user,
          ))

        console.log('loanRequestUpdater -> refreshToken:', response)

        updateAuthCredentials({ ...user, ...response })
      }
    } finally {
      setLoadingUpdate(false)
    }
  }

  useEffect(() => {
    if (!user) {
      localStorage.clear()
      navigate('/')
    } else {
      !isEmptyObject(A4UStorageData)
        ? (setLoanRequest(A4UStorageData as TUpdateLoanRequestPayload),
          getInitialState(A4UStorageData as TUpdateLoanRequestPayload))
        : navigate('/')
    }
  }, [])

  useEffect(() => {
    if (!user) {
      localStorage.clear()
      navigate('/')
    }
  }, [user])

  useEffect(() => {
    if (isEmptyObject(loanRequest)) return
  }, [loanRequest])

  useEffect(() => {
    if (isEmptyObject(updatePayload)) return

    setLoanRequest({ ...loanRequest, ...updatePayload })
    updateA4UStorageData({ ...loanRequest, ...updatePayload })
    loanRequestUpdater({ ...loanRequest, ...updatePayload })
    toTopHandler()
  }, [updatePayload])

  return (
    <section className={'quizPage'}>
      <div className="container">
        {!isEmptyObject(loanRequest) && (
          <>
            {step === EQuizStep.EMPLOYMENT && (
              <>
                <Employment
                  setStep={setStep}
                  setUpdatePayload={setUpdatePayload}
                  initialData={loanRequest}
                />
              </>
            )}
            {step === EQuizStep.GOAL && (
              <>
                <Goal
                  setStep={setStep}
                  setUpdatePayload={setUpdatePayload}
                  initialData={loanRequest}
                />
              </>
            )}
            {step === EQuizStep.LOAN && (
              <>
                <Loan
                  setStep={setStep}
                  setUpdatePayload={setUpdatePayload}
                  initialData={loanRequest}
                />
              </>
            )}
            {step === EQuizStep.REAL_ESTATE && (
              <>
                <RealEstate
                  setStep={setStep}
                  setUpdatePayload={setUpdatePayload}
                  initialData={loanRequest}
                />
              </>
            )}

            {step === EQuizStep.MERITAL_STATUS && (
              <>
                <Marital
                  setStep={setStep}
                  setUpdatePayload={setUpdatePayload}
                  initialData={loanRequest}
                />
              </>
            )}
            {step === EQuizStep.MERITAL_STATUS_MARIED && (
              <>
                <EmploymentPartner
                  setStep={setStep}
                  setUpdatePayload={setUpdatePayload}
                  initialData={loanRequest}
                />
              </>
            )}
            {step == EQuizStep.INCOME && (
              <>
                <Income
                  setStep={setStep}
                  setUpdatePayload={setUpdatePayload}
                  loanRequestUpdater={loanRequestUpdater}
                  initialData={loanRequest}
                />
              </>
            )}
            {step == EQuizStep.BANK_IGNORE && (
              <>
                <BankIgnore
                  setStep={setStep}
                  setUpdatePayload={setUpdatePayload}
                  loanRequestUpdater={loanRequestUpdater}
                  initialData={loanRequest}
                />
              </>
            )}
            {step == EQuizStep.WHATSAPP && (
              <>
                <WhatsApp setStep={setStep} isLoadingUpdate={isLoadingUpdate} />
              </>
            )}
          </>
        )}
      </div>
    </section>
  )
}
