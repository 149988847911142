export enum EQuizStep {
  EMPLOYMENT = 'EMPLOYMENT',
  GOAL = 'GOAL',
  LOAN = 'LOAN',
  REAL_ESTATE = 'REAL_ESTATE',
  MERITAL_STATUS = 'MERITAL_STATUS',
  MERITAL_STATUS_MARIED = 'MERITAL_STATUS_MARIED',
  INCOME = 'INCOME',
  BANK_IGNORE = 'BANK_IGNORE',
  WHATSAPP = 'WHATSAPP',
}
