/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IMaskInput, IMask } from 'react-imask'
import Select from 'react-select'
import classNames from 'classnames'
import ReactGA from 'react-ga'

import {
  EEmplymentType,
  ELoanReason,
  TUpdateLoanRequestPayload,
} from '@/core/api/types'

import { Button, EButtonType, RadioButtonGroup } from '@/components/ui'
import { BackPage, RenderTitle } from '@/components/smart'

import { EQuizStep } from '@/pages'
import './style.scss'

import { LodgingIcon } from './Lodging.icon'
import { CarIcon } from './Car.icon'
import { LoansIcon } from './Loans.icon'
import { OtherIcon } from './Other.icon'
import { BusinessIcon } from './Business.icon'
import { useAuth } from '@/core/context'

const configBlocks = {
  carYear: {
    mask: 'YYYY',
    blocks: {
      YYYY: {
        mask: IMask.MaskedRange,
        from: 2016,
        to: 2024,
      },
    },
  },
  carValue: {
    mask: '₪{ }VALUE',
    blocks: {
      VALUE: {
        mask: IMask.MaskedNumber,
        min: 0,
        max: 500000,
        thousandsSeparator: ',',
      },
    },
  },
}

const carOptions = [
  { value: 'acura', label: 'אקורה' },
  { value: 'alfa romeo', label: 'אלפא רומאו' },
  { value: 'aston martin', label: 'אסטון מרטין' },
  { value: 'audi', label: 'אאודי' },
  { value: 'bentley', label: 'בנטלי' },
  { value: 'bmw', label: 'ב.מ.וו' },
  { value: 'bugatti', label: 'בוגאטי' },
  { value: 'buick', label: 'ביואיק' },
  { value: 'byd', label: 'בי.וואי.די' },
  { value: 'cadillac', label: 'קדילאק' },
  { value: 'chevrolet', label: 'שברולט' },
  { value: 'chery', label: "צ'רי" },
  { value: 'chrysler', label: 'קרייזלר' },
  { value: 'citroën', label: 'סיטרואן' },
  { value: 'dongfeng motor', label: 'דונגפנג מוטור' },
  { value: 'dodge', label: "דודג'" },
  { value: 'ferrari', label: 'פרארי' },
  { value: 'fiat', label: 'פיאט' },
  { value: 'ford', label: 'פורד' },
  { value: 'genesis', label: "ג'נסיס" },
  { value: 'gmc', label: "ג'י.אם.סי" },
  { value: 'great wall motors', label: 'מוטורס גרייט וול' },
  { value: 'honda', label: 'הונדה' },
  { value: 'hyundai', label: 'היונדאי' },
  { value: 'infiniti', label: 'אינפיניטי' },
  { value: 'jaguar', label: "ג'גואר" },
  { value: 'jeep', label: "ג'יפ" },
  { value: 'kia', label: 'קיה' },
  { value: 'lamborghini', label: 'למבורגיני' },
  { value: 'land rover', label: 'לנד רובר' },
  { value: 'lexus', label: 'לקסוס' },
  { value: 'lincoln', label: 'לינקולן' },
  { value: 'lotus', label: 'לוטוס' },
  { value: 'maserati', label: 'מזראטי' },
  { value: 'mazda', label: 'מאזדה' },
  { value: 'mclaren', label: 'מקלארן' },
  { value: 'mercedes-benz', label: 'מרצדס' },
  { value: 'mg', label: 'mg' },
  { value: 'mini', label: 'מיני' },
  { value: 'mitsubishi', label: 'מיצובישי' },
  { value: 'nio', label: 'ניו' },
  { value: 'nissan', label: 'ניסאן' },
  { value: 'opel', label: 'אופל' },
  { value: 'pagani', label: 'פגאני' },
  { value: 'peugeot', label: "פיג'ו" },
  { value: 'porsche', label: 'פורשה' },
  { value: 'ram', label: 'ראם' },
  { value: 'renault', label: 'רנו' },
  { value: 'rolls-royce', label: 'רולס-רויס' },
  { value: 'saab', label: 'סאאב' },
  { value: 'seat', label: 'סיאט' },
  { value: 'saic motor', label: 'סאיק מוטור' },
  { value: 'subaru', label: 'סובארו' },
  { value: 'suzuki', label: 'סוזוקי' },
  { value: 'tesla', label: 'טסלה' },
  { value: 'toyota', label: 'טויוטה' },
  { value: 'volkswagen', label: 'פולקסווגן' },
  { value: 'volvo', label: 'וולוו' },
]

const carYearOptions = [
  { value: '2016', label: '2016' },
  { value: '2017', label: '2017' },
  { value: '2018', label: '2018' },
  { value: '2019', label: '2019' },
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
]
interface IGoalProps {
  setStep: Dispatch<SetStateAction<EQuizStep | undefined>>
  setUpdatePayload: Dispatch<SetStateAction<TUpdateLoanRequestPayload>>
  initialData: TUpdateLoanRequestPayload
}

export const Goal = ({
  setStep,
  setUpdatePayload,
  initialData,
}: IGoalProps) => {
  const { t } = useTranslation()
  const { A4UStorageData } = useAuth()

  const [reason, setReason] = useState(
    initialData.reason && initialData.reason !== 'N/A'
      ? initialData.reason
      : ('' as ELoanReason),
  )
  const [carType, setCarType] = useState<string>(
    initialData.carType && initialData.carType !== 'N/A'
      ? initialData.carType
      : '',
  )

  const [input, setInput] = useState(
    initialData.reason &&
      initialData.reason !== 'N/A' &&
      initialData.reason === ELoanReason.CAR
      ? {
          carBrand:
            initialData.carBrand && initialData.carBrand !== 'N/A'
              ? initialData.carBrand
              : '',
          carYear:
            initialData.carYear && initialData.carYear !== 'N/A'
              ? initialData.carYear
              : '',
          carValue:
            initialData.carValue && initialData.carValue !== 'N/A'
              ? initialData.carValue
              : '',
        }
      : {
          carBrand: '',
          carYear: '',
          carValue: '',
        },
  )
  const [carBrandIndexSelected, setCarBrandIndexSelected] = useState<
    number | undefined
  >(carOptions.findIndex((item) => item.value === input?.carBrand))
  const [carYearIndexSelected, setCarYearIndexSelected] = useState<
    number | undefined
  >(carYearOptions.findIndex((item) => item.value === input?.carYear))

  const [isValidCarYear] = useState(true)
  const [isValidCarValue] = useState(true)

  // const carYearErrorMessage = t('goalPage.carYearErrorMessage')
  // const carValueErrorMessage = t('goalPage.carValueErrorMessage')

  const onInputChange = ({ name, value }: { name: string; value: string }) => {
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const initialLoanList = () => {
    const data = [
      {
        value: ELoanReason.LODGING,
        name: 'lodging',
        icon: <LodgingIcon />,
        text: t('goalPage.goalDescriptionRadioGroup.lodging'),
        isSelected: false,
      },
      {
        value: ELoanReason.CAR,
        name: 'car',
        icon: <CarIcon />,
        text: t('goalPage.goalDescriptionRadioGroup.car'),
        isSelected: false,
      },
      {
        value: ELoanReason.LOANS,
        name: 'loans',
        icon: <LoansIcon />,
        text: t('goalPage.goalDescriptionRadioGroup.loans'),
        isSelected: false,
      },
      {
        value: ELoanReason.OTHER,
        name: 'other',
        icon: <OtherIcon />,
        text: t('goalPage.goalDescriptionRadioGroup.other'),
        isSelected: false,
      },
      {
        value: ELoanReason.BUSINESS,
        name: 'business',
        icon: <BusinessIcon />,
        text: t('goalPage.goalDescriptionRadioGroup.business'),
        isSelected: false,
      },
    ].map((item) => {
      return {
        ...item,
        isSelected: reason === item.value,
      }
    })
    if (
      A4UStorageData &&
      A4UStorageData.employmentType !== EEmplymentType.FREELANCER
    ) {
      const [, ...rest] = [...data].reverse()
      const withoutLast = rest.reverse()
      return withoutLast
    }
    return [...data]
  }

  const [loanList, setLoanList] = useState<
    {
      isSelected: boolean
      value: ELoanReason
      name: string
      icon: JSX.Element
      text: string
    }[]
  >([])

  useEffect(() => {
    console.log('employmentType', initialData.employmentType)
    setLoanList(initialLoanList())
  }, [initialData.employmentType])

  const selectionLoanHandler = (index: number) => {
    const item = loanList[index]
    if (item.isSelected) return
    console.log(item)
    item.isSelected = true

    setLoanList((prev) =>
      prev.map((_item) => ({
        ..._item,
        isSelected: item.value === _item.value,
      })),
    )
    setReason(item.value as ELoanReason)
  }

  const getCarInputs = () => {
    const carRef = useRef(null)

    useEffect(() => {
      if (carRef.current || reason === ELoanReason.CAR) {
        window.scrollTo({
          top: (carRef.current as unknown as HTMLDivElement).offsetTop + 30,
          behavior: 'smooth',
        })
      }
    }, [reason])
    if (reason === ELoanReason.CAR) {
      const radioHandler = (event: ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
        setCarType(event.target.value)
      }
      const options = [
        {
          value: 'NEW',
          name: 'car-types',
          label: 'חדש מיבואן',
        },
        {
          value: 'USED',
          name: 'car-types',
          label: 'יד שניה',
        },
      ]

      return (
        <div className="car-container" ref={carRef}>
          <RadioButtonGroup
            label=""
            options={options}
            onChange={radioHandler}
            selectedValue={carType}
          />
          <Select
            options={carOptions}
            placeholder={'בחר'}
            styles={{
              menu(base) {
                return {
                  ...base,
                  zIndex: 3,
                }
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary: '#6a4ae3',
                },
                spacing: {
                  ...theme.spacing,
                  controlHeight: 46,
                },
              }
            }}
            defaultValue={
              carBrandIndexSelected ? carOptions[carBrandIndexSelected] : null
            }
            isSearchable
            isDisabled={!carType}
            onChange={(newValue) => {
              setCarBrandIndexSelected(
                carOptions.findIndex((item) => item.value === newValue?.value),
              )
              if (newValue) {
                onInputChange({ name: 'carBrand', value: newValue.value })
              }
            }}
          />

          <Select
            options={carYearOptions}
            placeholder={'בחר'}
            styles={{
              menu(base) {
                return {
                  ...base,
                  zIndex: 3,
                }
              },
            }}
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary: '#6a4ae3',
                },
                spacing: {
                  ...theme.spacing,
                  controlHeight: 46,
                },
              }
            }}
            defaultValue={
              carYearIndexSelected ? carYearOptions[carYearIndexSelected] : null
            }
            isSearchable
            isDisabled={!carType || carType === 'NEW'}
            onChange={(newValue) => {
              setCarYearIndexSelected(
                carYearOptions.findIndex(
                  (item) => item.value === newValue?.value,
                ),
              )
              if (newValue) {
                onInputChange({ name: 'carYear', value: newValue.value })
              }
            }}
          />

          <div className="input-wrapper">
            <IMaskInput
              type="text"
              blocks={configBlocks}
              mask="carValue"
              value={input.carValue}
              disabled={!carType}
              onAccept={(_value, _mask, e) => {
                if (e) {
                  onInputChange({
                    // @ts-ignore
                    name: e!.target!.name as string,
                    value: _mask._unmaskedValue,
                  })
                }
              }}
              placeholder={t('goalPage.carPrice')}
              name="carValue"
            />
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const updateProfileHandler = () => {
    const payload = { reason: reason } as TUpdateLoanRequestPayload
    if (reason === ELoanReason.CAR) {
      payload['carType'] = carType
      payload.carYear = carType === 'NEW' ? 'N/A' : input.carYear
      payload.carBrand = input.carBrand
      payload.carValue = input.carValue
    } else {
      payload['carType'] = 'N/A'
      payload.carYear = 'N/A'
      payload.carBrand = 'N/A'
      payload.carValue = 'N/A'
    }

    ReactGA.event({
      category: 'Button Click',
      action: 'reason',
      label: 'Quiz Page',
    })
    ReactGA.send({
      fieldsObject: payload,
    })

    setUpdatePayload((prev) => ({
      ...prev,
      ...payload,
    }))
    setStep(EQuizStep.LOAN)
  }

  return (
    <>
      <BackPage onNavigate={() => setStep(EQuizStep.EMPLOYMENT)} />
      {/* <Button
          onClick={() => setStep(EQuizStep.LOAN)}
          disabled={
            !reason ||
            (reason === ELoanReason.CAR &&
              (!input.carBrand || !isValidCarYear || !isValidCarValue))
          }
        >
        </Button> */}
      <section className="goal">
        <RenderTitle titleKey={'goalPage.title'} />

        <>
          {loanList.length && (
            <ul
              className={classNames(
                'goal-list',
                `goal-list--${loanList.length}`,
              )}
            >
              {loanList.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => selectionLoanHandler(index)}
                    className={classNames('goal-list-item', {
                      active: item.isSelected,
                      [item.name]: item.value === ELoanReason.BUSINESS,
                    })}
                  >
                    <div className="goal-list-item__svg">{item.icon}</div>
                    <div className="goal-list-item__text">{item.text}</div>
                  </li>
                )
              })}
            </ul>
          )}

          {getCarInputs()}
        </>

        <Button
          type={EButtonType.button}
          disabled={
            !reason ||
            (reason === ELoanReason.CAR &&
              (!carType ||
                !input.carBrand ||
                !isValidCarYear ||
                !isValidCarValue))
          }
          onClick={updateProfileHandler}
        >
          {t('goalPage.action')}
        </Button>
      </section>
    </>
  )
}
