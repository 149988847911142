import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import { Intro } from '@/components/smart'
import { Button, EButtonType } from '@/components/ui'

import styles from './style.module.scss'

export const IntroPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'page.intro' })
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const params: {
    [x: string]: string
  } = {}

  searchParams.forEach((value, key) => {
    params[key] = value
  })

  const navigateToAuthHandler = () => {
    ReactGA.event({
      category: 'Button Click',
      action: 'Navigate to Auth Page',
      label: 'Intro Page',
    })
    navigate('auth', {
      state: {
        params,
      },
    })
  }

  return (
    <section className={styles.introPage}>
      <div className="container">
        <Intro title={t('title')} description={t('description')} />

        <Button type={EButtonType.button} onClick={navigateToAuthHandler}>
          {t('btn')}
        </Button>
      </div>
    </section>
  )
}
