import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { MainLayout, PrivateLayout } from '@/layout'

import './styles.css'

import { Remove } from './components/sections/Remove'
import { PaymentCongrats } from './components/sections/PaymentCongrats'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AuthPage, IntroPage, QuizPage, VerificationPage } from './pages'
import { Payment } from './components/sections/Payment'

import ReactGA from 'react-ga'
ReactGA.initialize('G-RP6BQ2S8X3')

function usePageTracking() {
  const location = useLocation()

  useEffect(() => {
    console.log(
      `updateing google analitics ${location.pathname} ${location.search}`,
    )
    ReactGA.pageview(location.pathname + location.search)
  }, [location])
}
function App() {
  useEffect(() => {
    const loadScriptByURL = async (
      id: string,
      url: string,
      callback: () => void,
    ) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }
    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${
        process.env.REACT_APP_RECAPCHA_PUB_KEY as string
      }`,
      function () {
        // setIsScriptLoaded(true)
        console.log('Script Loaded')
      },
    )
  }, [])
  usePageTracking()
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route index element={<IntroPage />}></Route>

        <Route path="/auth" element={<AuthPage />} />
        <Route path="/verification" element={<VerificationPage />} />

        <Route path="/remove" element={<Remove />} />
        <Route path="/finishprocess" element={<Payment />} />
      </Route>

      <Route path="quiz" element={<PrivateLayout />}>
        {/* <Route index element={<Quiz />} /> */}
        <Route index element={<QuizPage />} />
        {/* <Route path="page" element={<QuizPage />} /> */}
      </Route>

      <Route path="finishprocess" element={<PrivateLayout />}>
        <Route index element={<Payment />} />
        <Route path="finish" element={<PaymentCongrats />} />
      </Route>
    </Routes>
  )
}

export default App
