import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { useCallback } from 'react'

interface IBackPageProps {
  onNavigate: () => void
  hasText?: boolean
}

export const BackPage = ({ onNavigate, hasText = true }: IBackPageProps) => {
  const { t } = useTranslation()

  const prevPageHandler = useCallback(() => {
    onNavigate && onNavigate()
  }, [onNavigate])

  return (
    <div className={styles.container} onClick={prevPageHandler}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
      >
        <path
          d="M15.5 31C18.5656 31 21.5624 30.0909 24.1113 28.3878C26.6603 26.6846 28.647 24.2638 29.8201 21.4316C30.9933 18.5993 31.3002 15.4828 30.7022 12.4761C30.1041 9.4694 28.6279 6.70756 26.4602 4.53985C24.2924 2.37213 21.5306 0.895906 18.5239 0.297835C15.5172 -0.300237 12.4007 0.00671415 9.56841 1.17987C6.73615 2.35303 4.31538 4.3397 2.61222 6.88866C0.909061 9.43763 1.62306e-06 12.4344 1.35505e-06 15.5C9.9567e-07 19.6109 1.63303 23.5533 4.53984 26.4602C7.44666 29.367 11.3891 31 15.5 31ZM7.04546 14.0909L20.553 14.0909L17.322 10.8599C17.1874 10.7299 17.08 10.5744 17.0062 10.4025C16.9323 10.2306 16.8935 10.0457 16.8918 9.85857C16.8902 9.67147 16.9259 9.48592 16.9967 9.31275C17.0676 9.13958 17.1722 8.98225 17.3045 8.84995C17.4368 8.71764 17.5941 8.61301 17.7673 8.54216C17.9405 8.47131 18.126 8.43566 18.3131 8.43729C18.5002 8.43891 18.6851 8.47778 18.857 8.55163C19.0289 8.62548 19.1844 8.73283 19.3144 8.86741L24.9508 14.5038C25.2149 14.768 25.3633 15.1264 25.3633 15.5C25.3633 15.8736 25.2149 16.232 24.9508 16.4962L19.3144 22.1326C19.1844 22.2672 19.0289 22.3745 18.857 22.4484C18.6851 22.5222 18.5002 22.5611 18.3131 22.5627C18.126 22.5643 17.9405 22.5287 17.7673 22.4578C17.5941 22.387 17.4368 22.2824 17.3045 22.1501C17.1722 22.0178 17.0676 21.8604 16.9967 21.6873C16.9259 21.5141 16.8902 21.3285 16.8918 21.1414C16.8935 20.9543 16.9323 20.7694 17.0062 20.5975C17.08 20.4256 17.1874 20.2701 17.322 20.1401L20.553 16.9091L7.04546 16.9091C6.67174 16.9091 6.31333 16.7606 6.04908 16.4964C5.78482 16.2321 5.63636 15.8737 5.63636 15.5C5.63636 15.1263 5.78482 14.7679 6.04908 14.5036C6.31333 14.2394 6.67174 14.0909 7.04546 14.0909Z"
          fill="#6A4AE3"
        />
      </svg>
      {hasText && <div>{t('backHandler')}</div>}
    </div>
  )
}
