/* eslint-disable @typescript-eslint/ban-ts-comment */
import { HTMLAttributes, forwardRef, useRef } from 'react'
import classNames from 'classnames'

import './style.scss'

interface InputProps extends HTMLAttributes<HTMLInputElement> {
  type: 'text' | 'number' | 'email' | 'password'
  value: string | number
  name?: string
  placeholder?: string
  error?: string
  disabled?: boolean
  readOnly?: boolean
  className?: string
  autofocus?: boolean
}

// eslint-disable-next-line react/display-name
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      value,
      name,
      placeholder,
      disabled,
      error,
      className,
      autofocus,
      ...rest
    },
    ref,
  ) => {
    const inputRef = useRef(null)
    return (
      <>
        <div
          className={classNames(className, 'input-wrapper', {
            'has-error': error,
          })}
          {...{ ...rest, ref }}
        >
          <input
            {...{ ...rest }}
            type={type}
            ref={inputRef}
            value={value}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete="new-password"
            autoFocus={autofocus}
          />
          {<div className="error">{error}</div>}
        </div>
      </>
    )
  },
)
